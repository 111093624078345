import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PermissionDetailsV2, PermissionsService } from '../../lockvue-ng-sdk';
import { TeamDetailsUIModel } from '../../models/TeamDetailsUIModel';

@Component({
  selector: 'lockvue-remove-member-from-team-confirmation',
  templateUrl: './remove-member-from-team-confirmation.component.html',
  styleUrls: ['./remove-member-from-team-confirmation.component.scss'],
})
export class RemoveMemberFromTeamConfirmationComponent {
  @Input() team: TeamDetailsUIModel;
  @Input() memberUserId: string;
  @Input() permissions: PermissionDetailsV2[];
  @Input() revokePermissions: boolean = true;

  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
    private permissionsService: PermissionsService,
  ) {}

  cancel() {
    this.ref.close(false);
  }

  submit() {
    if (this.revokePermissions) {
      this.performPermissionRevocation();
    } else {
      this.ref.close(true);
    }
  }

  performPermissionRevocation() {
    if (this.permissions && this.permissions.length > 0) {
      const totalPermissions = this.permissions.length;
      let processedPermissions = 0;
      this.permissions.forEach(permission => {
        this.permissionsService.permissionsRevoke(permission.PermissionId).subscribe(result => {
          ++processedPermissions;
          if (processedPermissions >= totalPermissions) {
            this.ref.close(true);
          }
        });
      });
    }
    this.ref.close(true);
  }
}
