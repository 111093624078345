import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';

@Injectable({
  providedIn: 'root',
})
export class BrowserSupportService {
  constructor() {}

  isSupportedBrowser(): boolean {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName().toLowerCase();
    const browserVersion = browser.getBrowserVersion();
    return (
      browserName === 'chrome' ||
      browserName === 'firefox' ||
      (browserName === 'microsoft edge' && parseInt(browserVersion.substr(0, 2), 10) >= 83)
    );
  }
}
