<div>
  <input
    placeholder="{{ text }}"
    type="text"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
    nbInput
    fullWidth
    [nbAutocomplete]="auto" />

  <nb-autocomplete #auto (selectedChange)="onTagAdd($event)">
    <nb-option *ngFor="let l of filteredLocks$ | async" value="{{ l.Id }}">
      {{ l.DisplayName }}&nbsp;<small class="text-muted">({{ l.UID }})</small>
    </nb-option>
  </nb-autocomplete>
</div>

<nb-card class="mt-2">
  <nb-tag-list (tagRemove)="onTagRemove($event)">
    <nb-tag
      *ngFor="let l of selectedLocksSet"
      [text]="l.Name"
      [role]="l.Id"
      [removable]="l.Removable"></nb-tag>
  </nb-tag-list>
</nb-card>
