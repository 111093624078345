<div class="window">
  <div class="inner">
    <label>Old Password</label>
    <input
      nbInput
      fullWidth
      name="old"
      type="password"
      [(ngModel)]="newPassword.OldPassword"
      placeholder="Enter Old Password" />
  </div>
  <div class="inner">
    <label>New Password</label>
    <input
      nbInput
      fullWidth
      name="old"
      #p="ngModel"
      type="password"
      [(ngModel)]="newPassword.NewPassword"
      placeholder="Enter New Password" />
    <small class="text-muted">
      Passwords must be at least 7 characters in length and must contain an upper case letter, a
      lower case letter and a numeric character.
    </small>
  </div>
  <div class="inner">
    <label>Confirm New Password</label>
    <input
      nbInput
      fullWidth
      name="old"
      type="password"
      [(ngModel)]="confirmPassword"
      placeholder="Confirm New Password"
      [status]="
        confirmPassword && newPassword.NewPassword !== confirmPassword ? 'danger' : 'basic'
      " />
  </div>
  <div class="inner">
    <button
      nbButton
      (click)="changePassword()"
      class="mt-2"
      [disabled]="
        !newPassword.NewPassword ||
        newPassword.NewPassword.length < 7 ||
        newPassword.NewPassword !== confirmPassword ||
        (p.touched && p.invalid)
      ">
      Change Password
    </button>
  </div>
</div>
