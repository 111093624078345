import { Component } from '@angular/core';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { SystemService } from '../../../lockvue-ng-sdk';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <footer>
      Powered by
      <div class="lockview-footer-logo"></div>
      v{{ feVersion }} / {{ beVersion }}
    </footer>
  `,
})
export class FooterComponent {
  public feVersion: string;
  public beVersion: string;

  constructor(
    private configService: LockvueConfigProviderService,
    private systemService: SystemService,
  ) {
    this.feVersion = this.configService.getDashboardVersion();
    this.systemService.systemVersion().subscribe(data => {
      this.beVersion = data;
    });
  }
}
