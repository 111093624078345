import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lockvue-lock-name',
  templateUrl: './lock-name.component.html',
  styleUrls: ['./lock-name.component.scss'],
})
export class LockNameComponent {
  @Input() value: string;
  @Input() maxLength: number = 30; // default

  constructor() {}

  getText(): string {
    if (!this.value) {
      return 'Noname';
    }
    if (this.value.length <= this.maxLength) {
      return this.value;
    }
    return this.value.slice(0, this.maxLength) + '...';
  }
}
