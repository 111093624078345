<div class="lock-details">
  <h2>{{ lock.DisplayName }}</h2>
  <hr />
  <div>
    <span><b>Lock Type:</b></span>
    <span>{{ lock.LockType }} (lock.Subtype)</span>
  </div>
  <br />
  <div>
    <span><b>Serial Number: </b></span>
    <span>{{ lock.UID }}</span>
  </div>
  <br />
  <div *ngIf="lock.LastUnlockedBy">
    <div>
      <span><b>Last Unlocked: </b></span>
      <lockvue-last-unlock
        [lastUnlockedBy]="lock.LastUnlockedBy"
        [lastUnlockedAtUtc]="lock.LastUnlockedAtUtc">
      </lockvue-last-unlock>
    </div>
    <br />
  </div>
  <div *ngIf="lock.Location">
    <span><b>Location: </b></span>
    <div class="map-div">
      <lockvue-embedded-map
        [latitude]="lock.Location.Latitude"
        [longitude]="lock.Location.Longitude"
        [showLargeMapText]="true">
      </lockvue-embedded-map>
    </div>
  </div>
</div>
