<nb-card>
  <nb-card-body>
    <table style="vertical-align: middle; margin: 10px">
      <colgroup>
        <col style="width: 20%" />
        <col [class.highlighted]="highlightedColumn === 'Admin'" />
        <col [class.highlighted]="highlightedColumn === 'Maintenance'" />
        <col [class.highlighted]="highlightedColumn === 'Restricted'" />
        <col [class.highlighted]="highlightedColumn === 'OneTime'" />
      </colgroup>
      <thead>
        <tr>
          <th style="font-size: 18px; text-align: left; min-width: 20%" class="col">Permissions</th>
          <th class="col">Admin</th>
          <th class="col">Maintenance</th>
          <th class="col">Restricted</th>
          <th style="white-space: nowrap"><span class="col">One-Time</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="subheading">
            <b>{{ clientName }} Web Portal </b>
            <span class="small text-muted">(email registered users only)</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>View locks</div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>Share locks</div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>Edit locks</div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>View & revoke permissions to locks</div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>
              View ALL activity
              <span class="text-muted small"
                >(full historical unlock activity & location data)</span
              >
            </div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>View last unlock activity <span class="text-muted small">(who & when)</span></div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>
              View last location activity
              <span class="text-muted small">(where unlock occured)</span>
            </div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="subheading">
            <b>{{ clientName }} Mobile App</b>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>View locks and associated permissions</div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>Unlock smart locks</div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
        </tr>
        <tr>
          <td class="permission">
            <div class="bullet"></div>
            <div>Remove shackle <span class="text-muted small">(HD Padlock only)</span></div>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-checkmark-round" status="success"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
          <td class="permission-icon">
            <nb-icon icon="ion-close-round" status="danger"></nb-icon>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="float-right m-2">
      <button nbButton (click)="close()">Close</button>
    </div>
  </nb-card-body>
</nb-card>
