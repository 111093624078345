import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'lockvue-timezone-aware-date-time',
  templateUrl: './timezone-aware-date-time.component.html',
  styleUrls: ['./timezone-aware-date-time.component.scss'],
})
export class TimezoneAwareDateTimeComponent {
  @Input() value: Date;

  /**
   * Text to be used when the value (Date) is not set.
   * Default is 'Indefinite'
   */
  @Input() unkownText: string = 'Indefinite';

  private timezone: string;

  constructor() {
    this.timezone = moment.tz.guess();
  }

  getFormattedDateTime() {
    return this.value
      ? moment.tz(this.value + 'Z', this.timezone).format('DD-MMM-YYYY hh:mm:ss a z')
      : this.unkownText;
  }
}
