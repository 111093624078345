import { Injectable } from '@angular/core';
import { UserDetailsLimited } from '../lockvue-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class UserFilterService {
  constructor() {}

  public process(filter: string, userDetails: UserDetailsLimited): boolean {
    // No filter restrictions apply
    if (!filter) {
      return true;
    }
    filter = filter.toLowerCase();

    // Check if the search text is found in user display name
    if (
      userDetails &&
      userDetails.DisplayName &&
      userDetails.DisplayName.toLowerCase().indexOf(filter) > -1
    ) {
      return true;
    }

    // Check if search text is found in user id (email address)
    if (
      userDetails &&
      userDetails.UserId &&
      userDetails.UserId.toLowerCase().indexOf(filter) > -1
    ) {
      return true;
    }

    // No match found
    return false;
  }
}
