<div>
  <span><b>Last Known Status: </b>{{ LastLockStatus.split('_').join(' ') }}</span>
</div>
<br />
<div>
  <span
    ><b>Last Status Updated at: </b
    >{{ LastLockStatusUpdatedAt + 'Z' | date: 'd-MMM-yyyy hh:mm:ss a' }} ({{
      LastLockStatusUpdatedAt + 'Z' | amTimeAgo
    }})</span
  >
</div>
