import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HubDetailsModel, HubsAdminService, HubsService } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-hub-detail-window',
  templateUrl: './hub-detail-window.component.html',
  styleUrls: ['./hub-detail-window.component.scss'],
})
export class HubDetailWindowComponent implements OnInit, OnDestroy {
  @Input() hub: HubDetailsModel;
  @Input() isAdmin: boolean;
  lockSource: LocalDataSource;
  isKeyrack: boolean;

  // for auto refresh
  autoRefresh = true;
  intervalTime = 30000;

  destroy$ = new Subject<void>();

  constructor(private hubService: HubsService, private hubsAdminService: HubsAdminService) {}

  ngOnInit(): void {
    this.lockSource = new LocalDataSource(this.hub.Locks);
    this.isKeyrack = this.hub.HubType.includes('KeyRack');

    if (this.autoRefresh && this.isKeyrack) {
      const numbers = interval(this.intervalTime);
      numbers.pipe(takeUntil(this.destroy$)).subscribe(x => this.refresh());
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  refresh() {
    if (!this.isAdmin) {
      this.hubService
        .hubsDetails(this.hub.HubId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(hub => {
          this.hub = hub;
          this.lockSource.load(this.hub.Locks);
        });
    } else {
      this.hubsAdminService
        .hubsAdminDetails(this.hub.HubId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(hub => {
          this.hub = hub;
          this.lockSource.load(this.hub.Locks);
        });
    }
  }
}
