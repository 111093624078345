import { Component, OnInit, Input } from '@angular/core';
import { LockActivityUIModel } from '../../models/LockActivityUIModel';
import { EventAction, LockActivityModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-activity-action',
  templateUrl: './activity-action.component.html',
  styleUrls: ['./activity-action.component.scss'],
})
export class ActivityActionComponent implements OnInit {
  @Input() value: EventAction;
  @Input() rowData: LockActivityUIModel;
  requestValue = '';

  constructor() {}

  ngOnInit() {
    if (this.rowData.EventAction === 'Request') {
      this.requestValue = this.splitCamelCase(this.rowData.Value);
    }
  }

  private splitCamelCase(string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
