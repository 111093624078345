/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SystemSummaryModel { 
    Locks?: number;
    Groups?: number;
    Teams?: number;
    UserProfiles?: number;
    AllPermissions?: number;
    ActivePermissions?: number;
    AvailableLocks?: number;
}