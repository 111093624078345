import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CodesService, NewCodeModel } from '../../lockvue-ng-sdk';
import { takeUntil } from 'rxjs/operators';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import * as moment from 'moment';

@Component({
  selector: 'lockvue-new-unique-code',
  templateUrl: './new-unique-code.component.html',
  styleUrls: ['./new-unique-code.component.scss'],
})
export class NewUniqueCodeComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Input() value: NewCodeModel;
  inProgress: boolean;
  @Output() uniqueCodeSuccess: EventEmitter<string>;
  dateTimeVisible: boolean = true;

  constructor(
    private codesService: CodesService,
    private notificationsService: NotificationMessagesService,
  ) {
    this.uniqueCodeSuccess = new EventEmitter();
    this.value = {};
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createUniqueCode() {
    this.codesService
      .codesAdd(this.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        newCode => {
          this.uniqueCodeSuccess.emit(newCode);
          this.notificationsService.showSuccess(
            'Success',
            'New Unique Code ( ' + newCode + ' ) successfully created.',
          );
        },
        error => {
          this.notificationsService.showError(
            'Error',
            'Error while creating a new Unique Code.',
            error,
          );
        },
      );
  }

  filterDate({ value }) {
    return (
      value <= moment().add(6, 'months').valueOf() && value >= moment().add(-1, 'days').valueOf()
    );
  }
}
