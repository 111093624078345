<nb-card accent="danger" [nbSpinner]="loading">
  <nb-card-header>Too many pages!</nb-card-header>
  <nb-card-body>
    <div>
      <p>The document that you are trying to create is about {{ approxPages }} pages.</p>
      <p>If you continue, it may take several minutes to create the file.</p>
      <p>Please filter the table if you want less data.</p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="close(false)">Filter data</button>
    <button nbButton outline status="danger" class="ml-2" (click)="close(true)">
      Continue Anyway
    </button>
  </nb-card-footer>
</nb-card>
