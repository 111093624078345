import { Component, Input } from '@angular/core';
import { TeamMemberModel } from '../../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss'],
})
export class TeamMemberComponent {
  constructor() {}

  @Input() members: TeamMemberModel[];
}
