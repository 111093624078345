/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminCodeDetailsModel } from '../model/adminCodeDetailsModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class CodesAdminService {

    protected basePath = 'http://localhost:5192/api/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Returns all active unique codes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesAdminActive(observe?: 'body', reportProgress?: boolean): Observable<Array<AdminCodeDetailsModel>>;
    public codesAdminActive(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminCodeDetailsModel>>>;
    public codesAdminActive(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminCodeDetailsModel>>>;
    public codesAdminActive(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminCodeDetailsModel>>('get',`${this.basePath}/admin/Codes/Active`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all unique codes generated between the given dates.
     * 
     * @param From Start time in UTC for filtering codes by its creation time
     * @param To End time in UTC for filtering codes by its creation time
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public codesAdminAll(From?: Date, To?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<AdminCodeDetailsModel>>;
    public codesAdminAll(From?: Date, To?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AdminCodeDetailsModel>>>;
    public codesAdminAll(From?: Date, To?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AdminCodeDetailsModel>>>;
    public codesAdminAll(From?: Date, To?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (From !== undefined && From !== null) {
            queryParameters = queryParameters.set('from', <any>From.toISOString());
        }
        if (To !== undefined && To !== null) {
            queryParameters = queryParameters.set('to', <any>To.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AdminCodeDetailsModel>>('get',`${this.basePath}/admin/Codes/All`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
