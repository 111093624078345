import { Component, Input, OnInit } from '@angular/core';
import { HubDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-hub-card',
  templateUrl: './hub-card.component.html',
  styleUrls: ['./hub-card.component.scss'],
})
export class HubCardComponent {
  @Input() hub: HubDetailsModel;

  constructor() {}
}
