import { Component, OnInit, Input } from '@angular/core';
import { LockStatsItem } from '../../models/lock-stats-item';

@Component({
  selector: 'lockvue-lock-stats-pie-chart',
  templateUrl: './lock-stats-pie-chart.component.html',
  styleUrls: ['./lock-stats-pie-chart.component.scss'],
})
export class LockStatsPieChartComponent implements OnInit {
  options: any = {};
  _lockStats: LockStatsItem[];

  @Input() set lockStats(value: LockStatsItem[]) {
    this._lockStats = value;
    this.refreshChart();
  }

  get lockStats(): LockStatsItem[] {
    return this._lockStats;
  }

  constructor() {
    this._lockStats = [];
  }

  ngOnInit() {
    this.refreshChart();
  }

  refreshChart() {
    this.options = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c}',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
        data: this._lockStats ? this._lockStats.map(x => x.manufacturer) : ['No data found'],
      },
      series: [
        {
          name: 'Locks',
          type: 'pie',
          radius: '80%',
          center: ['65%', '50%'],
          data: this._lockStats
            ? this._lockStats.map(x => ({
                value: x.total,
                name: x.manufacturer,
              }))
            : [],
          itemStyle: {
            normal: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }
}
