<lockvue-lock-info-short [iconColor]="iconColor" [value]="value"></lockvue-lock-info-short>
<!-- <nb-icon *ngIf="showTransactionIcon" class="p-1" icon="ion-information" size="small" nbTooltip="Click to see more details"
    (click)="emitClickOnName()"></nb-icon> -->
<span
  *ngIf="showTransactionIcon"
  nbTooltip="Click to see more details"
  (click)="emitClickOnName()"
  class="small text-muted link">
  &nbsp; <span class="link-text">(Activity Details)</span>
</span>
