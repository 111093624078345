import { Component, Input } from '@angular/core';
import { LockDetailsModel } from '../../lockvue-ng-sdk';
import { GroupActivityModel } from '../models/GroupActivityModel';

@Component({
  selector: 'lockvue-group-action-details',
  templateUrl: './group-action-details.component.html',
  styleUrls: ['./group-action-details.component.scss'],
})
export class GroupActionDetailsComponent {
  constructor() {}

  @Input() value: LockDetailsModel;

  @Input() rowData: GroupActivityModel;
}
