<h1 id="title" class="title">Password Reset</h1>
<p class="sub-title">Enter the email address you used for registration and your new password.</p>

<form (ngSubmit)="submit()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="email"
      name="email"
      type="email"
      id="input-email"
      pattern=".+@.+\..+"
      placeholder="Email"
      autofocus
      [required]="true" />
  </div>
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="password"
      name="password"
      type="password"
      id="input-password"
      placeholder="New Password"
      [required]="true" />
    <small class="text-muted">
      Passwords must be at least 7 characters in length and must contain an upper case letter, a
      lower case letter and a numeric character.
    </small>
  </div>
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="confirmPassword"
      name="confirm-password"
      type="password"
      id="input-confirm-password"
      placeholder="Confirm New Password"
      [required]="true" />
  </div>
  <button
    nbButton
    fullWidth
    status="success"
    [disabled]="submitted || !form.valid"
    [class.btn-pulse]="submitted">
    Submit
  </button>
</form>
<section class="another-action" aria-label="Login">
  <a class="text-link" routerLink="/auth/login">Click here</a> to login instead.
</section>
