import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ApiKeysService } from './api/apiKeys.service';
import { CodesService } from './api/codes.service';
import { CodesAdminService } from './api/codesAdmin.service';
import { HubsService } from './api/hubs.service';
import { HubsAdminService } from './api/hubsAdmin.service';
import { LockGroupsService } from './api/lockGroups.service';
import { LockGroupsAdminService } from './api/lockGroupsAdmin.service';
import { LockPermissionsService } from './api/lockPermissions.service';
import { LockPermissionsAdminService } from './api/lockPermissionsAdmin.service';
import { LocksService } from './api/locks.service';
import { LocksAdminService } from './api/locksAdmin.service';
import { LocksPoolAdminService } from './api/locksPoolAdmin.service';
import { PermissionsService } from './api/permissions.service';
import { ProfileService } from './api/profile.service';
import { ReportsService } from './api/reports.service';
import { SummaryService } from './api/summary.service';
import { SummaryAdminService } from './api/summaryAdmin.service';
import { SystemService } from './api/system.service';
import { TeamsService } from './api/teams.service';
import { TeamsAdminService } from './api/teamsAdmin.service';
import { TelemetryService } from './api/telemetry.service';
import { TelemetryAdminService } from './api/telemetryAdmin.service';
import { UsersAdminService } from './api/usersAdmin.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApiKeysService,
    CodesService,
    CodesAdminService,
    HubsService,
    HubsAdminService,
    LockGroupsService,
    LockGroupsAdminService,
    LockPermissionsService,
    LockPermissionsAdminService,
    LocksService,
    LocksAdminService,
    LocksPoolAdminService,
    PermissionsService,
    ProfileService,
    ReportsService,
    SummaryService,
    SummaryAdminService,
    SystemService,
    TeamsService,
    TeamsAdminService,
    TelemetryService,
    TelemetryAdminService,
    UsersAdminService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
