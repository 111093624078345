<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline" pack="eva"></nb-icon>
    </a>
    <div class="logo" (click)="navigateHome()"></div>
  </div>
  <!-- <nb-select *ngIf="isDropdownVisible" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary"
    class="pl-2">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action *ngIf="!user">
      <a href="/auth/login">Login</a>
    </nb-action>
    <nb-action *ngIf="user">
      <nb-user
        [nbContextMenu]="userMenu"
        [nbContextMenuTag]="tag"
        [name]="userDisplayName ? userDisplayName : user?.nameid"></nb-user>
    </nb-action>
  </nb-actions>
</div>
