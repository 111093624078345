import { Pipe, PipeTransform } from '@angular/core';
import { isObservable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  transform(value: any): unknown {
    return isObservable(value)
      ? value.pipe(
          map(v => ({ loading: false, value: v })),
          startWith({ loading: true }),
        )
      : value;
  }
}
