<i
  class="show-hide-toggle"
  nbTooltip="More Info"
  [nbTooltipDisabled]="open"
  [class.nb-arrow-thin-left]="!open"
  [class.nb-arrow-thin-right]="open"
  (click)="toggleStatistics()">
</i>
<div class="slide-out-container" [class.expanded]="open" [class.collapsed]="!open">
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
