import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TrendsUtilsService {
  constructor() {}

  // Parses data and generates (t, y) data points using given configuration; where t is the date
  // time value and y is the number of counts
  getDatapoints(dataArray, dateTimePropertyName, frequency, filterFunc): any[] {
    const dateFormat = this.getBucketNameForAggregation(frequency);
    const buckets = {};

    // Parse data array and create buckets with total counts
    for (let i = 0; i < dataArray.length; i++) {
      const current = dataArray[i];
      const dateTime = current[dateTimePropertyName] + 'Z';
      const label = moment(dateTime).format(dateFormat);

      // Bucket does not exist yet
      if (!buckets[label]) {
        buckets[label] = 0;
      }

      if (!filterFunc || filterFunc(current)) {
        // Add one to the total count
        buckets[label] = buckets[label] + 1;
      }
    }

    // Prepare data points from bucket data
    const dataPoints = [];
    const keys = Object.keys(buckets);
    for (let i = 0; i < keys.length; i++) {
      dataPoints.push({
        t: moment(keys[i]),
        y: buckets[keys[i]],
      });
    }

    // Return results
    return dataPoints;
  }

  getDatasets(datasetLabels, data) {
    const datasets = [];
    for (let i = 0; i < data.length; i++) {
      const dataset = {
        label: datasetLabels ? datasetLabels[i % datasetLabels.length] : '',
        data: data[i],
        cubicInterpolationMode: 'monotone',
        backgroundColor: this.getColor(i, 0.6),
        borderColor: this.getColor(i, 0.75),
      };
      datasets.push(dataset);
    }
    return datasets;
  }

  getDefaultChart(datasets, frequency) {
    // Default Label(s)
    const today = moment().format('YYYY-MM-DD[T]HH:mm[:00Z]');
    const labels = [today]; // Must provide labels otherwise it does not work

    // Chart with default settings
    const trendsChartSettings = {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: frequency,
                displayFormats: {
                  quarter: 'MMM YYYY',
                },
              },
            },
          ],
          yAxes: [
            {
              type: 'linear',
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
      },
    };
    return trendsChartSettings;
  }

  getRecommendedTrendsFrequency(start: Date, end: Date) {
    const dateDiff = moment(end).diff(start, 'days');
    if (dateDiff < 3) {
      return 'hour';
    } else if (dateDiff < 90) {
      return 'day';
    } else if (dateDiff < 5 * 365) {
      return 'month';
    } else {
      return 'year';
    }
  }

  // Gets bucket name (date string format) that can be used for aggregating values
  private getBucketNameForAggregation(frequency: string) {
    switch (frequency) {
      case 'year':
        return 'YYYY[-01-01T00:00:00]';
      case 'month':
        return 'YYYY-MM[-01T00:00:00]';
      case 'day':
        return 'YYYY-MM-DD[T00:00:00]';
      case 'hour':
        return 'YYYY-MM-DD[T]HH[:00:00]';
      default:
        throw new Error('Invalid frequency type: ' + frequency);
    }
  }

  // Gets a color from a pre-defined palette (circular array)
  private getColor(index, alpha) {
    const colorsRgb = ['68,170,153', '136,204,238', '221,204,119', '204, 102, 119', '170, 68, 153'];
    if (alpha) {
      return 'rgba(' + colorsRgb[index % colorsRgb.length] + ',' + alpha + ')';
    }
    return 'rgb(' + colorsRgb[index % colorsRgb.length] + ')';
  }
}
