import { Injectable } from '@angular/core';
import { ActivityAwareLockInfoShortComponent } from '../common/activity-aware-lock-info-short/activity-aware-lock-info-short.component';
import { LockInfoShortComponent } from '../common/lock-info-short/lock-info-short.component';
import { TableConstantsProviderService } from './table-constants-provider.service';

@Injectable({
  providedIn: 'root',
})
export class TableColumnProviderService {
  constructor(private tableConstants: TableConstantsProviderService) {}

  getLocksPageColumns(locks) {
    const columns = {};
    columns['DisplayName'] = this.tableConstants.getDisplayName();
    columns['LockType'] = this.tableConstants.getLockType(locks);
    columns['Subtype'] = this.tableConstants.getSubtype(locks);
    columns['UID'] = this.tableConstants.vanillaColumn('Serial Number');
    columns['LastBatteryLevel'] = this.tableConstants.LastBatteryLevel;
    columns['Permission'] = this.tableConstants.getPermission(true);
    return columns;
  }

  getGroupLocksPageColumns(locks) {
    const columns = {};
    columns['DisplayName'] = this.tableConstants.getDisplayName();
    columns['LockType'] = this.tableConstants.getLockType(locks);
    columns['Subtype'] = this.tableConstants.getSubtype(locks);
    columns['UID'] = this.tableConstants.vanillaColumn('Serial Number');
    columns['LastBatteryLevel'] = this.tableConstants.LastBatteryLevel;
    return columns;
  }

  getGroupTableViewColumns() {
    const columns = {};
    columns['Name'] = this.tableConstants.vanillaColumn('Group Name');
    columns['Description'] = this.tableConstants.vanillaColumn('Description');
    columns['Locks'] = this.tableConstants.countArray('Number of Locks');
    return columns;
  }

  getTeamTableViewColumns() {
    const columns = {};
    columns['Name'] = this.tableConstants.vanillaColumn('Group Name');
    columns['Description'] = this.tableConstants.vanillaColumn('Description');
    columns['Members'] = this.tableConstants.countArray('Number of Members');
    return columns;
  }

  getLockStatusColumns(locks) {
    const columns = {};
    columns['DisplayName'] = this.tableConstants.getDisplayName();
    columns['LockType'] = this.tableConstants.getLockType(locks);
    columns['Subtype'] = this.tableConstants.getSubtype(locks);
    columns['UID'] = this.tableConstants.vanillaColumn('Serial Number');
    columns['LockStatus'] = this.tableConstants.lockStatusColumn;
    columns['LockStatusUpdatedOnUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      'Last Lock Status At',
      true,
      'Unknown',
    );
    columns['LastUnlockedAtUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      'Last Unlocked At',
      true,
      'Unknown',
    );
    return columns;
  }

  getHubsPageColumn(isKeyrack) {
    const nameSuffix = isKeyrack ? 'Peg' : 'Lock';
    const columns = {};
    columns['LockSerialNumber'] = this.tableConstants.vanillaColumn('Serial Number');
    columns['LockDetails'] = this.tableConstants.getDisplayName(`${nameSuffix} Name`, true);
    if (isKeyrack) {
      columns['Row'] = this.tableConstants.vanillaColumn('List');
      columns['Column'] = this.tableConstants.vanillaColumn('Position');
    }
    columns['Details'] = this.tableConstants.getHubActionButton('Details', 'locks');
    columns['Activity'] = this.tableConstants.getHubActionButton(
      'Activity',
      'activity/lock-activity',
    );
    return columns;
  }

  getHubsTableViewColumns(isAdmin) {
    const columns = {};
    columns['Name'] = this.tableConstants.vanillaColumn('Name');
    columns['Description'] = this.tableConstants.vanillaColumn('Description');
    columns['HubType'] = this.tableConstants.HubType;
    columns['HubId'] = this.tableConstants.vanillaColumn('Hub Id');
    columns['LastHeartbeatUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      'Last Heartbeat',
      true,
      'Unknown',
    );
    columns['TotalRows'] = this.tableConstants.vanillaColumn('Total Strips');
    columns['Locks'] = this.tableConstants.getTotalHubLocks();
    columns['UID'] = this.tableConstants.vanillaColumn('UID');
    columns['HWAddress'] = this.tableConstants.vanillaColumn('HWAddress');
    columns['MoreInfo'] = this.tableConstants.getHubViewLocksButton(isAdmin);
    return columns;
  }

  getPermissionPageColumns() {
    const columns = {};
    columns['LockDetails'] = this.tableConstants.getLockInfoShort(LockInfoShortComponent);
    columns['PermissionType'] = this.tableConstants.getPermission();
    columns['User'] = this.tableConstants.getUserDetailsComponent('Granted To', false);
    // TODO: change UserAlias to true when API updated with the field.
    columns['GrantedBy'] = this.tableConstants.getUserDetailsComponent('Granted By', false);
    columns['DeactivationTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime('Expires On');
    return columns;
  }
  getGroupPermissionColumns() {
    const columns = {};
    columns['LockDetails'] = this.tableConstants.getLockInfoShort(LockInfoShortComponent);
    columns['PermissionType'] = this.tableConstants.getPermission();
    columns['GrantedTo'] = this.tableConstants.getUserDetailsComponent('Granted To', false);
    // TODO: change UserAlias to true when API updated with the field.
    columns['GrantedBy'] = this.tableConstants.getUserDetailsComponent('Granted By', false);
    columns['DeactivationTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime('Expires On');
    return columns;
  }

  getCodesPageColumns(isPastData) {
    const expiresOnTitle = isPastData ? 'Expired On' : 'Expires On';
    const columns = {};
    columns['Code'] = this.tableConstants.vanillaColumn('Code');
    columns['Description'] = this.tableConstants.vanillaColumn('Description');
    columns['ExpiresOnUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      expiresOnTitle,
      isPastData,
    );
    return columns;
  }

  getActivityPageColumns() {
    const columns = {};
    columns['LockDetails'] = this.tableConstants.getLockInfoShort(
      ActivityAwareLockInfoShortComponent,
    );
    columns['EventAction'] = this.tableConstants.EventAction;
    columns['DateTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      'Performed On',
      true,
      'Unknown',
    );
    columns['User'] = this.tableConstants.getUserDetailsComponent('User', true);
    return columns;
  }

  getAdminActivityPageColumns() {
    const columns = this.getActivityPageColumns();
    columns['ClientId'] = this.tableConstants.ClientIdFromLockDetails;
    columns['TransactionId'] = this.tableConstants.vanillaColumn('Transaction Id');
    return columns;
  }

  getGroupActivityColumns() {
    const columns = {};
    columns['Resource'] = this.tableConstants.GroupName;
    columns['EventAction'] = this.tableConstants.EventAction;
    columns['DateTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      'Performed On',
      true,
      'Unknown',
    );
    columns['User'] = this.tableConstants.getUserDetailsComponent('User', true);
    columns['ValueLock'] = this.tableConstants.GroupActionDetails;
    return columns;
  }

  getTeamActivityColumns() {
    const columns = {};
    columns['Resource'] = this.tableConstants.TeamName;
    columns['EventAction'] = this.tableConstants.EventAction;
    columns['DateTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime(
      'Performed On',
      true,
      'Unknown',
    );
    columns['User'] = this.tableConstants.getUserDetailsComponent('User', true);
    columns['Value'] = {
      title: 'Action Details',
      type: 'string',
      valuePrepareFunction: cell => (!cell ? 'NA' : cell),
    };
    return columns;
  }

  getTeamMembersColumns() {
    const columns = {};
    columns['DisplayName'] = this.tableConstants.vanillaColumn('User Name');
    columns['UserId'] = this.tableConstants.vanillaColumn('User Email');
    return columns;
  }

  getApiKeysColumns() {
    const columns = {};
    columns['Description'] = this.tableConstants.vanillaColumn('Description');
    columns['Key'] = this.tableConstants.ApiKeyDetails;
    columns['CreatedOnUtc'] = this.tableConstants.getTimezoneAwareDateTime('Created On', false);
    return columns;
  }

  getAdminLocksPageColumn(locks) {
    const columns = {};
    columns['DisplayName'] = this.tableConstants.getDisplayName();
    columns['LockType'] = this.tableConstants.getLockType(locks);
    columns['Subtype'] = this.tableConstants.getSubtype(locks);
    columns['UID'] = this.tableConstants.vanillaColumn('Serial Number');
    columns['HWAddress'] = this.tableConstants.vanillaColumn('HWAddress');
    columns['Owner'] = this.tableConstants.getUserDetailsComponent('Owner', false);
    columns['ClientId'] = this.tableConstants.vanillaColumn('ClientId');
    columns['NotBilled'] = this.tableConstants.getNotBilled();
    columns['Id'] = this.tableConstants.MoreInfo;
    return columns;
  }

  getAdminLocksPoolPageColumn(locks) {
    const columns = {};
    columns['SerialNumber'] = this.tableConstants.vanillaColumn('Serial Number');
    columns['LockType'] = this.tableConstants.getLockType(locks);
    columns['Subtype'] = this.tableConstants.getSubtype(locks);
    columns['HWAddress'] = this.tableConstants.vanillaColumn('HWAddress');
    columns['AddedBy'] = this.tableConstants.vanillaColumn('Added By');
    columns['AddedOnUTC'] = this.tableConstants.getTimezoneAwareDateTime('Added On', true);
    columns['NotBilled'] = this.tableConstants.getNotBilled();
    return columns;
  }

  // separating out admin and user here gives more maintainability in future if anything changes.
  getAdminHubsPageColumn(isKeyrack, hubId) {
    const columns = {};
    columns['LockSerialNumber'] = this.tableConstants.vanillaColumn('Serial Number');
    if (isKeyrack) {
      columns['Row'] = this.tableConstants.vanillaColumn('List');
      columns['Column'] = this.tableConstants.vanillaColumn('Position');
    }
    columns['Details'] = this.tableConstants.getHubActionButton('Details', 'admin/locks');
    columns['Activity'] = this.tableConstants.getHubActionButton('Activity', 'admin/activity');
    columns['Remove'] = this.tableConstants.getRemoveLockFromHub(hubId, isKeyrack);
    return columns;
  }

  getAdminPermissionPageColumn() {
    const columns = {};
    columns['PermissionId'] = this.tableConstants.vanillaColumn('Permission Id');
    columns['LockDetails'] = this.tableConstants.getLockInfoShort(LockInfoShortComponent);
    columns['PermissionType'] = this.tableConstants.getPermission();
    columns['GrantedTo'] = this.tableConstants.getUserDetailsComponent('Granted To', false);
    // TODO: change UserAlias to true when API updated with the field.
    columns['GrantedBy'] = this.tableConstants.getUserDetailsComponent('Granted By', false);
    columns['ActivationTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime('Active From');
    columns['DeactivationTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime('Expires On');
    columns['ClientId'] = this.tableConstants.vanillaColumn('Client Id');
    return columns;
  }

  getAdminActivationPageColumn() {
    const columns = {};
    columns['LockDetails'] = this.tableConstants.getLockInfoShort(LockInfoShortComponent);
    columns['User'] = this.tableConstants.getUserDetailsComponent('User', false);
    columns['DateTimeUtc'] = this.tableConstants.getTimezoneAwareDateTime('Activated On', true);
    columns['NotBilled'] = this.tableConstants.getNotBilled('string');
    columns['AppId'] = this.tableConstants.vanillaColumn('Client');
    return columns;
  }

  getAdminRegistrationPageColumn() {
    const columns = {};
    columns['UserId'] = this.tableConstants.vanillaColumn('User Id');
    columns['DisplayName'] = this.tableConstants.vanillaColumn('Display Name');
    columns['Company'] = this.tableConstants.vanillaColumn('Company');
    columns['MemberSinceUtc'] = this.tableConstants.getTimezoneAwareDateTime('Member Since', true);
    columns['Integration'] = this.tableConstants.Integration;
    columns['ClientIds'] = this.tableConstants.vanillaColumn('Client');
    return columns;
  }

  getAdminCodesPageColumn() {
    const columns = {};
    columns['Code'] = this.tableConstants.vanillaColumn('Code');
    columns['Description'] = this.tableConstants.vanillaColumn('Description');
    columns['CreatedOnUtc'] = this.tableConstants.getTimezoneAwareDateTime('Created On', true);
    columns['ExpiresOnUtc'] = this.tableConstants.getTimezoneAwareDateTime('Expires On');
    columns['Owner'] = this.tableConstants.vanillaColumn('Owner');
    return columns;
  }

  getAdminBulkActivatioPageColumn() {
    const columns = {};
    columns['DisplayName'] = this.tableConstants.vanillaColumn('Display Name');
    columns['SerialNumber'] = this.tableConstants.vanillaColumn('Seiral Number');
    columns['OwnerId'] = this.tableConstants.vanillaColumn('Owner Id');
    columns['ClientId'] = this.tableConstants.vanillaColumn('Client Id');
    columns['Status'] = this.tableConstants.vanillaColumn('Status');
    return columns;
  }
}
