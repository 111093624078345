import { Component, OnInit, Input } from '@angular/core';
import { LockStatsItem } from '../../models/lock-stats-item';

@Component({
  selector: 'lockvue-lock-stats-bar',
  templateUrl: './lock-stats-bar.component.html',
  styleUrls: ['./lock-stats-bar.component.scss'],
})
export class LockStatsBarComponent {
  @Input() value: LockStatsItem[] = [];

  constructor() {}
}
