<div class="container">
  <nb-stepper orientation="horizontal" [disableStepNavigation]="true" #stepper>
    <nb-step label="Lock Group">
      <div>
        <lockvue-basic-details [(name)]="lockGroupName" [(description)]="lockGroupDescription">
        </lockvue-basic-details>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton nbStepperNext (click)="createLockGroup()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Locks">
      <h6>Add locks to this lock group</h6>
      <div>
        <lockvue-lock-selector (selectedLocksChange)="selectedLocksChange($event)">
        </lockvue-lock-selector>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton nbStepperNext (click)="addSelectedLock()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Done">
      <h6>Success</h6>
      <div class="results">
        <p>Congratulations! You have successfully created your Lock Group.</p>
        <p>
          You can add more locks to the group by clicking on edit lock from the top right corner.
        </p>
        <p>Lock Groups allow you to group multiple locks and share them together.</p>
        <p>
          To make any changes please send us a message via the
          <a href="https://lockvue.zendesk.com/hc/en-us/requests/new" target="_blank">Support</a>
          page.
        </p>
      </div>
    </nb-step>
  </nb-stepper>
</div>
