/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SortOrder = 'Ascending' | 'Descending';

export const SortOrder = {
    Ascending: 'Ascending' as SortOrder,
    Descending: 'Descending' as SortOrder
};