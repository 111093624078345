import { Component, Input } from '@angular/core';
import { LockDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-group-locks',
  templateUrl: './group-locks.component.html',
  styleUrls: ['./group-locks.component.scss'],
})
export class GroupLocksComponent {
  constructor() {}

  @Input() locks: LockDetailsModel[];
}
