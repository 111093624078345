<div class="container">
  <form [formGroup]="detailsForm" (ngSubmit)="!isEditing ? detailsSubmit() : editSubmit()">
    <div class="form-group">
      <label for="name" class="form-control-label">Name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Name"
        id="Name"
        nbInput
        fullWidth
        formControlName="Name"
        [status]="
          detailsForm.controls.Name.invalid &&
          (detailsForm.dirty || detailsForm.controls.Name.touched)
            ? 'danger'
            : 'basic'
        " />
    </div>
    <div class="form-group">
      <label for="description" class="form-control-label">Description</label>
      <input
        type="text"
        class="form-control"
        placeholder="Description"
        id="Description"
        nbInput
        fullWidth
        formControlName="Description"
        [status]="
          detailsForm.controls.Description.invalid && (detailsForm.dirty || detailsForm.touched)
            ? 'danger'
            : 'basic'
        " />
    </div>
    <div class="form-group">
      <label for="HubType" class="form-control-label">Hub Type</label>
      <nb-select
        placeholder="Select HubType"
        fullWidth
        id="HubType"
        formControlName="HubType"
        [status]="
          detailsForm.controls.HubType.invalid &&
          (detailsForm.dirty || detailsForm.controls.HubType.touched)
            ? 'danger'
            : 'basic'
        ">
        <nb-option *ngFor="let hubType of hubTypes" value="{{ hubType }}">{{ hubType }}</nb-option>
      </nb-select>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label for="UID" class="form-control-label">UID</label>
        <input
          type="text"
          class="form-control"
          placeholder="UID"
          id="UID"
          nbInput
          fullWidth
          formControlName="UID"
          [status]="
            detailsForm.controls.UID.invalid &&
            (detailsForm.dirty || detailsForm.controls.UID.touched)
              ? 'danger'
              : 'basic'
          " />
      </div>
      <div class="col-md-6 form-group">
        <label for="HWAddress" class="form-control-label">HWAddress</label>
        <input
          type="text"
          class="form-control"
          placeholder="HWAddress"
          id="HWAddress"
          nbInput
          fullWidth
          formControlName="HWAddress"
          [status]="
            detailsForm.controls.HWAddress.invalid &&
            (detailsForm.dirty || detailsForm.controls.HWAddress.touched)
              ? 'danger'
              : 'basic'
          " />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label for="TotalRows" class="form-control-label">Total Rows</label>
        <input
          type="text"
          class="form-control"
          placeholder="Number of Rows"
          id="TotalRows"
          nbInput
          fullWidth
          formControlName="TotalRows"
          [status]="
            detailsForm.controls.TotalRows.invalid &&
            (detailsForm.dirty || detailsForm.controls.TotalRows.touched)
              ? 'danger'
              : 'basic'
          " />
      </div>
      <div class="col-md-6 form-group">
        <label for="TotalColumns" class="form-control-label">Total Columns</label>
        <input
          type="text"
          class="form-control"
          placeholder="Number of Columns"
          id="TotalColumns"
          nbInput
          fullWidth
          formControlName="TotalColumns"
          [status]="
            detailsForm.controls.TotalColumns.invalid &&
            (detailsForm.dirty || detailsForm.controls.TotalColumns.touched)
              ? 'danger'
              : 'basic'
          " />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group" *ngIf="!isEditing">
        <nb-checkbox formControlName="ActivateOnCreation" id="ActivateOnCreation"
          >Activate on creation?</nb-checkbox
        >
      </div>
      <div class="col-md-6 form-group" *ngIf="isEditing">
        <nb-checkbox formControlName="NotBilled" id="NotBilled">Not Billed?</nb-checkbox>
      </div>
      <div class="col-md-6 form-group">
        <!-- <nb-checkbox formControlName="OfflineEnabeled" id="OfflineEnabeled">Offline Enabled?</nb-checkbox> -->
      </div>
    </div>
    <div class="wizard-nav-buttons">
      <button nbButton>submit</button>
    </div>
  </form>
</div>
