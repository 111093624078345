<!-- <ng2-smart-table [source]="lockSource" [settings]="settings"></ng2-smart-table> -->
<div class="table-container">
  <ng2-smart-table [settings]="settings" [source]="lockSource" (rowHover)="onRowSelect($event)">
  </ng2-smart-table>
</div>
<!-- <div *ngIf="!isAdmin">
  <ngx-slide-out [open]="true">
      <span *ngIf='!selectedLock'>
          Please select a lock to view details.
      </span>

      <div *ngIf='selectedLock'>
          <lockvue-hub-slideout [lock]="selectedLock"></lockvue-hub-slideout>
      </div>
  </ngx-slide-out>
</div> -->
