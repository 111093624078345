<nb-card class="lv-confirmation-container">
  <nb-card-header>
    <div>Are you sure?</div>
  </nb-card-header>
  <nb-card-body>
    <p>
      Are you sure you want to remove <b>{{ memberUserId }}</b> from
      <b>{{ team.Name ? team.Name : 'the selected team' }}</b
      >?
    </p>
    <br />
    <div *ngIf="permissions && permissions.length > 0">
      <p *ngIf="permissions.length === 1">
        There is a permission granted to the member via this team.
      </p>
      <p *ngIf="permissions.length > 1">
        There are {{ permissions.length }} permissions granted to the member via this team.
      </p>
      <p>
        <b
          >Please note that removing the member will remove the permissions associated with the
          members.</b
        >
      </p>
      <!-- <p>
        <input
          id="inputRemoveAssociatedPermissions"
          type="checkbox"
          [(ngModel)]="revokePermissions" />
        &nbsp;
        <label for="inputRemoveAssociatedPermissions"> Revoke associated lock permissions. </label>
      </p> -->
    </div>
    <p>
      <small>NOTE: This action cannot be undone.</small>
    </p>
  </nb-card-body>
  <nb-card-footer>
    <div class="wizard-nav-buttons">
      <button nbButton status="danger" (click)="submit()">Confirm</button>
      <button nbButton outline (click)="cancel()">Cancel</button>
    </div>
  </nb-card-footer>
</nb-card>
