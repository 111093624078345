import { Component, Input, OnInit } from '@angular/core';
import { LockDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-hub-slideout',
  templateUrl: './hub-slideout.component.html',
  styleUrls: ['./hub-slideout.component.scss'],
})
export class HubSlideoutComponent {
  @Input() lock: LockDetailsModel;

  constructor() {}
}
