/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Platform = 'Unknown' | 'Android' | 'Web' | 'iOS';

export const Platform = {
    Unknown: 'Unknown' as Platform,
    Android: 'Android' as Platform,
    Web: 'Web' as Platform,
    IOS: 'iOS' as Platform
};