import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LockGroupDetailsUIModel } from '../../models/LockGroupDetailsUIModel';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';
import { PermissionDetailsV2, PermissionsService } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-remove-lock-from-group-confirmation',
  templateUrl: './remove-lock-from-group-confirmation.component.html',
  styleUrls: ['./remove-lock-from-group-confirmation.component.scss'],
})
export class RemoveLockFromGroupConfirmationComponent {
  @Input() group: LockGroupDetailsUIModel;
  @Input() lock: LockDetailsUIModel;
  @Input() permissions: PermissionDetailsV2[];
  @Input() revokePermissions: boolean = true;

  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
    private permissionsService: PermissionsService,
  ) {}

  cancel() {
    this.ref.close(false);
  }

  submit() {
    if (this.revokePermissions) {
      this.performPermissionRevocation();
    } else {
      this.ref.close(true);
    }
  }

  performPermissionRevocation() {
    if (this.permissions && this.permissions.length > 0) {
      const totalPermissions = this.permissions.length;
      let processedPermissions = 0;
      this.permissions.forEach(permission => {
        this.permissionsService.permissionsRevoke(permission.PermissionId).subscribe(result => {
          ++processedPermissions;
          if (processedPermissions >= totalPermissions) {
            this.ref.close(true);
          }
        });
      });
    }
    this.ref.close(true);
  }
}
