import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminNewHubComponent } from '../../admin/admin-new-hub/admin-new-hub.component';
import { SubjectService } from '../../services/subject.service';
import { TableSettingsProviderService } from '../../services/table-settings-provider.service';

@Component({
  selector: 'lockvue-hub-table',
  templateUrl: './hub-table.component.html',
  styleUrls: ['./hub-table.component.scss'],
})
export class HubTableComponent implements OnInit, OnDestroy {
  @Input() isAdmin = false;
  @Input() isKeyRack = false;

  @Input() hubSource: LocalDataSource;
  settings: any;

  private destroy$ = new Subject();

  @Output() tableFiltered: EventEmitter<number> = new EventEmitter();

  constructor(
    private tableSettingsProvider: TableSettingsProviderService,
    private windowService: NbWindowService,
    private subjectService: SubjectService,
  ) {}

  ngOnInit(): void {
    this.initTableSettings();
    this.hubSource.onChanged().subscribe(event => {
      if (event.action === 'filter' || event.action === 'sort') {
        this.hubSource.getFilteredAndSorted().then(data => {
          this.tableFiltered.emit(data.length);
        });
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initTableSettings() {
    this.settings = this.tableSettingsProvider.getHubsTableViewSetting(this.isAdmin);
  }

  editHub($event) {
    const editHub = this.windowService.open(AdminNewHubComponent, {
      title: 'Edit Hub',
      context: { selectedHub: $event.data },
    });
    editHub.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.subjectService.emitWithMessage({ for: 'refreshHubs', value: true }));
  }
}
