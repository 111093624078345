/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Datatype = 'Unlocks' | 'ActiveUsers' | 'Registrations' | 'AppRegistrations' | 'Requests' | 'LockActivations' | 'LockShares';

export const Datatype = {
    Unlocks: 'Unlocks' as Datatype,
    ActiveUsers: 'ActiveUsers' as Datatype,
    Registrations: 'Registrations' as Datatype,
    AppRegistrations: 'AppRegistrations' as Datatype,
    Requests: 'Requests' as Datatype,
    LockActivations: 'LockActivations' as Datatype,
    LockShares: 'LockShares' as Datatype
};