import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CodeDetailsModel, CodesService } from '../../lockvue-ng-sdk';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';
import { TableSettingsProviderService } from '../../services/table-settings-provider.service';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'lockvue-unique-codes-table',
  templateUrl: './unique-codes-table.component.html',
  styleUrls: ['./unique-codes-table.component.scss'],
})
export class UniqueCodesTableComponent implements OnInit {
  tableSettings: any;
  private _codes: CodeDetailsModel[];
  codesSource: LocalDataSource = new LocalDataSource();

  @Output() tableFiltered: EventEmitter<CodeDetailsModel[]> = new EventEmitter();

  @Input() set codes(value: CodeDetailsModel[]) {
    this._codes = value;
    this.codesSource.load(this._codes);
    this.codesSource.onChanged().subscribe(event => {
      if (event.action === 'filter' || event.action === 'sort') {
        this.codesSource.getFilteredAndSorted().then(data => {
          this.tableFiltered.emit(data);
        });
      }
    });
    this.initTableSettings();
  }

  /**
   * Changes the table column header to "Expired On" and hides actions column if true.
   */
  @Input() isPastData: boolean = false;

  constructor(
    private notificationsService: NotificationMessagesService,
    private codesService: CodesService,
    private confirmDialogService: ConfirmDialogService,
    private tableSettingsProvider: TableSettingsProviderService,
  ) {}

  ngOnInit() {
    this.initTableSettings();
  }

  private initTableSettings() {
    this.tableSettings = this.tableSettingsProvider.getCodesPageSettings(this.isPastData);
  }

  onDeleteConfirm(event): void {
    this.confirmDialogService
      .open('Are you sure you want to remove this Unique Code?')
      .subscribe(result => {
        if (result) {
          const code = event.data.Code;
          this.codesService.codesDelete(code).subscribe(
            () => {
              this.notificationsService.showSuccess('Success', 'Unique Code removed successfully.');
              event.confirm.resolve();
            },
            error => {
              event.confirm.reject();
              this.notificationsService.showError(
                'Error',
                'There was an error while removing unique code: ' + code,
                error,
              );
            },
          );
        } else {
          event.confirm.reject();
        }
      });
  }
}
