import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { HttpClient } from '@angular/common/http';
import { NotificationMessagesService } from '../../../services/notification-messages.service';

@Component({
  selector: 'lockvue-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  email: string;
  password: any;
  confirmPassword: any;
  displayFirstName: string;
  displayLastName: string;
  phoneNumber: string;
  company: string;
  submitted: boolean;

  constructor(
    private http: HttpClient,
    private configProvider: LockvueConfigProviderService,
    private notificationsService: NotificationMessagesService,
    protected router: Router,
  ) {}

  register(): void {
    this.submitted = true;
    const url =
      this.configProvider.getAuthServerUrl() +
      '/Account/register?clientId=' +
      this.configProvider.getClientId();
    this.http
      .post(url, {
        Email: this.email,
        Password: this.password,
        DisplayName: this.makeDisplayName(this.displayFirstName, this.displayLastName),
        PhoneNumber: this.phoneNumber,
        Company: this.company,
      })
      .subscribe(
        result => {
          // redirect to reigster-success page
          this.router.navigate(['/auth/register-success']);
        },
        error => {
          this.notificationsService.showError(
            'Error',
            'We encountered an error while registering your account.',
            error,
          );
          this.submitted = false;
        },
      );
  }

  makeDisplayName(firstName: string, lastName: string): string {
    return firstName.trim() + ' ' + lastName.trim();
  }
}
