<div class="lock-details">
  <lockvue-lock-info-short [value]="value" [large]="true"> </lockvue-lock-info-short>
  <div>
    <span><b>Last Unlock: </b></span>
    <lockvue-last-unlock
      [lastUnlockedAtUtc]="value.LastUnlockedAtUtc"
      [lastUnlockedBy]="value.LastUnlockedBy">
    </lockvue-last-unlock>
  </div>
  <br />
  <div *ngIf="value.LockStatus">
    <lockvue-lock-status
      [LastLockStatus]="value.LockStatus"
      [LastLockStatusUpdatedAt]="value.LockStatusUpdatedOnUtc">
    </lockvue-lock-status>
    <br />
  </div>
  <div>
    <span><b>Location: </b></span>
    <div *ngIf="value.Location" class="map-div">
      <lockvue-embedded-map
        [latitude]="value.Location.Latitude"
        [longitude]="value.Location.Longitude"
        [showLargeMapText]="true">
      </lockvue-embedded-map>
    </div>
    <div *ngIf="!value.Location">
      <span class="italic">No location information available.</span>
    </div>
  </div>
</div>
