<div class="d-md-flex">
  <input
    type="button"
    nbButton
    outline
    [nbPopover]="popoverTemplate"
    nbPopoverPlacement="bottom"
    nbPopoverTrigger="noop"
    nbPopoverClass="custom-popover"
    (click)="open()"
    [value]="buttonText" />

  <ng-template #popoverTemplate>
    <div class="mx-2">
      <nb-radio-group [(ngModel)]="option" class="d-grid">
        <nb-radio
          *ngFor="let o of options"
          [value]="o.value"
          [checked]="o.value === previousOption">
          {{ o.label }}
        </nb-radio>
      </nb-radio-group>
      <div *ngIf="option === 'Custom'">
        <div>
          <label class="label text-small">Start Date</label>
          <br />
          <input
            nbInput
            placeholder="Start Date Time"
            fieldSize="tiny"
            [nbDatepicker]="startDatePicker"
            [value]="startDate ?? ''" />
          <nb-date-timepicker
            format="dd-MM-yyyy hh:mm:ss a"
            #startDatePicker
            singleColumn
            [twelveHoursFormat]="true"
            [value]="startDate"
            [step]="15"
            (dateTimeChange)="startDateChange($event)"></nb-date-timepicker>
        </div>
        <div>
          <label class="label text-small">End Date</label><br />
          <input
            nbInput
            placeholder="End Date Time"
            fieldSize="tiny"
            [value]="endDate ?? ''"
            [nbDatepicker]="endDatePicker" />
          <nb-date-timepicker
            #endDatePicker
            format="dd-MM-yyyy hh:mm:ss a"
            [step]="15"
            [twelveHoursFormat]="true"
            singleColumn
            (dateTimeChange)="endDateChange($event)"></nb-date-timepicker>
        </div>
      </div>
      <div class="float-right my-2">
        <button nbButton class="mr-2" size="tiny" outline status="primary" (click)="apply()">
          Apply
        </button>
        <button nbButton size="tiny" outline (click)="close()">Cancel</button>
      </div>
    </div>
  </ng-template>
</div>
