import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LockvueBaseConfigService {
  constructor() {}

  /**
   * Returns the client-id to be used for every request to LockVue
   * backend platform
   */
  getClientId(): string {
    return environment.clientId;
  }

  /**
   * Returns the URL of the API endpoints of auth related services.
   * The result includes api suffix without a trailing slash '/' and
   * does not include any controller references.
   *
   * E.g.: https://auth.lockvue.com/api
   */
  getAuthServerUrl(): string {
    return environment.authServerUrl;
  }

  /**
   * Gets the front-end version number of this dashboard
   */
  getDashboardVersion(): string {
    return '2.16.1';
  }

  /**
   * Returns the array of hub types
   */
  getHubTypes(): string[] {
    return [
      'Astute Access Hub',
      'Astute Access Cabinet',
      'Astute Access KeyRack',
      'Astute Access KeyRack Cabinet',
      'Matrix Key Cabinet',
    ];
  }

  getDWLClientName(): string {
    return 'LockVue';
  }

  getSupportEmail(): string {
    return 'support@lockvue.com';
  }

  getClientSpecificUrls(): { support: string; privacy: string; terms: string } {
    return {
      support: 'https://support.lockvue.com',
      privacy: 'https://www.lockvue.com/privacy-policy.html',
      terms: 'https://www.lockvue.com/terms-of-use.html',
    };
  }
}
