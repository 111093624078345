import { Component, OnInit, Input } from '@angular/core';
import { LockPermissionUIModel } from '../../models/LockPermissionUIModel';

@Component({
  selector: 'lockvue-permission-info',
  templateUrl: './permission-info.component.html',
  styleUrls: ['./permission-info.component.scss'],
})
export class PermissionInfoComponent {
  @Input() value: LockPermissionUIModel;

  constructor() {}
}
