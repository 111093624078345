import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lockvue-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.scss'],
})
export class SwitchInputComponent {
  checked: boolean;

  @Input()
  get value() {
    return this.checked;
  }

  set value(val: boolean) {
    this.checked = val;
    this.valueChange.emit(this.checked);
  }

  @Output() valueChange = new EventEmitter();

  constructor() {}
}
