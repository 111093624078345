import { Injectable } from '@angular/core';
import { GroupActionDetailsComponent } from '../activities/group-action-details/group-action-details.component';
import { GroupNameComponent } from '../activities/group-name/group-name.component';
import { TeamNameComponent } from '../activities/teams/team-name/team-name.component';
import { AdminMoreInfoComponent } from '../admin/admin-more-info/admin-more-info.component';
import { ActivityActionComponent } from '../common/activity-action/activity-action.component';
import { BatteryLevelComponent } from '../common/battery-level/battery-level.component';
import { HubActionButtonComponent } from '../common/hub-action-button/hub-action-button.component';
import { HubViewLocksButtonComponent } from '../common/hub-view-locks-button/hub-view-locks-button.component';
import { LockBilledComponent } from '../common/lock-billed/lock-billed.component';
import { LockNameComponent } from '../common/lock-name/lock-name.component';
import { RemoveLockFromHubButtonComponent } from '../common/remove-lock-from-hub-button/remove-lock-from-hub-button.component';
import { TimezoneAwareDateTimeComponent } from '../common/timezone-aware-date-time/timezone-aware-date-time.component';
import { UserAliasComponent } from '../common/user-alias/user-alias.component';
import { UserDetailsItemComponent } from '../common/user-details-item/user-details-item.component';
import { UserIntegrationComponent } from '../common/user-integration/user-integration.component';
import { DateFilterService } from './date-filter.service';
import { LockFilterService } from './lock-filter.service';
import { LockvueComparatorsService } from './lockvue-comparators.service';
import { LockvueConfigProviderService } from './lockvue-config-provider.service';
import { SubjectService } from './subject.service';
import { TableSettingsUtilService } from './table-settings-util.service';
import { UserFilterService } from './user-filter.service';
import { ViewKeyComponent } from '../api-keys/view-key/view-key.component';

@Injectable({
  providedIn: 'root',
})
export class TableConstantsProviderService {
  constructor(
    private comparatorService: LockvueComparatorsService,
    private tableSettingsUtil: TableSettingsUtilService,
    private configService: LockvueConfigProviderService,
    private subjectService: SubjectService,
    private lockFilterService: LockFilterService,
    private userFilterService: UserFilterService,
    private dateFilterService: DateFilterService,
  ) {}

  getDisplayName(title = 'Lock Name', isHub = false) {
    const lockDetails = {
      title,
      type: 'custom',
      renderComponent: LockNameComponent,
      compareFunction: this.comparatorService.stringComparator,
    };
    // Hub model doesn't have Lockdetails available in object it's wrapped by LockDetails
    if (isHub) {
      lockDetails['valuePrepareFunction'] = (cell, row) =>
        row.LockDetails ? row.LockDetails.DisplayName : '';
      lockDetails['filterFunction'] = (cell?: any, search?: string) =>
        cell.DisplayName.toLowerCase().includes(search.toLowerCase());
    }

    return lockDetails;
  }

  lockStatusColumn = {
    title: 'Last Lock Status',
    type: 'string',
    valuePrepareFunction: cell => (cell ? cell.split('_').join(' ') : 'Unknown'),
    editable: false,
    filter: {
      type: 'list',
      config: {
        selectText: 'All',
        list: [
          { value: 'Unlocked', title: 'Unlocked' },
          { value: 'Locked', title: 'Locked' },
          { value: 'Shackle_Removed', title: 'Shackle Removed' },
        ],
      },
    },
  };

  LastBatteryLevel = {
    title: 'Battery Level',
    type: 'custom',
    renderComponent: BatteryLevelComponent,
    editable: false,
    compareFunction: this.comparatorService.batteryComparator,
    filter: {
      type: 'list',
      config: {
        selectText: 'All',
        list: [
          { value: 'Low', title: 'Low (<30%)' },
          { value: 'Unknown', title: 'Unknown' },
        ],
      },
    },
    filterFunction(cell: any, search?: string): boolean {
      const intVal = parseInt(cell, 10);
      switch (search) {
        case 'Low':
          return !isNaN(intVal) && intVal < 30;
        case 'Unknown':
          return isNaN(intVal);
      }
      console.error(cell);
      console.dir(search);
      return true;
    },
  };

  EventAction = {
    title: 'Action',
    type: 'custom',
    renderComponent: ActivityActionComponent,
    editable: false,
    filter: {
      type: 'list',
      config: {
        selectText: 'All',
        list: this.tableSettingsUtil.getActionFilters(),
      },
    },
    filterFunction: (cell: any, search?: string) =>
      this.tableSettingsUtil.activityActionFilterFunction(cell, search),
  };

  Integration = {
    title: 'Integrations',
    type: 'custom',
    valuePrepareFunction: (cell, row) => {
      return {
        YBI: row.EnableYBIIntegration,
        Homhero: row.EnableHOMheroIntegration,
      };
    },
    renderComponent: UserIntegrationComponent,
  };

  MoreInfo = {
    title: 'More Info',
    type: 'custom',
    filter: false,
    valuePrepareFunction: (cell, row) => row,
    renderComponent: AdminMoreInfoComponent,
  };

  HubType = {
    title: 'Hub Type',
    type: 'string',
    compareFunction: this.comparatorService.stringComparator,
    filter: {
      type: 'list',
      config: {
        selectText: 'All',
        list: this.configService.getHubTypes().map(v => ({ value: v, title: v })),
      },
    },
  };

  ClientIdFromLockDetails = {
    title: 'ClientId',
    type: 'string',
    editable: false,
    valuePrepareFunction: (cell, row) => row.LockDetails.ClientId.toString(),
    compareFunction: this.comparatorService.stringComparator,
    filter: false,
  };

  GroupName = {
    title: 'Group Details',
    type: 'custom',
    renderComponent: GroupNameComponent,
    editable: false,
    compareFunction: this.comparatorService.groupNameComparator,
    filterFunction: (cell: any, search?: string) =>
      this.tableSettingsUtil.groupNameFilterFunction(cell, search),
  };

  TeamName = {
    title: 'Team Details',
    type: 'custom',
    renderComponent: TeamNameComponent,
    editable: false,
    compareFunction: this.comparatorService.groupNameComparator,
    filterFunction: (cell: any, search?: string) =>
      this.tableSettingsUtil.groupNameFilterFunction(cell, search),
  };

  GroupActionDetails = {
    title: 'Action Details',
    type: 'custom',
    renderComponent: GroupActionDetailsComponent,
    filterFunction: (cell, search) => this.lockFilterService.process(search, cell, true),
    compareFunction: this.comparatorService.lockDetailsComparator,
  };

  ApiKeyDetails = {
    title: 'Api Key',
    type: 'custom',
    renderComponent: ViewKeyComponent,
  };

  getRemoveLockFromHub(hubId, isKeyrack) {
    return {
      title: 'Remove',
      filter: false,
      type: 'custom',
      valuePrepareFunction: (cell, row) => {
        return {
          hubId,
          serial: row.LockSerialNumber,
          isKeyrack,
        };
      },
      renderComponent: RemoveLockFromHubButtonComponent,
      onComponentInitFunction: instance => {
        instance.lockRemoved.subscribe(x =>
          this.subjectService.emitWithMessage({ for: 'hubsAdmin', value: true }),
        );
      },
    };
  }

  getHubActionButton(title, url) {
    return {
      title,
      type: 'custom',
      valuePrepareFunction: (cell, row) => {
        return {
          title,
          url,
          queryParams: { SerialNumber: row.LockSerialNumber },
        };
      },
      renderComponent: HubActionButtonComponent,
      filter: false,
    };
  }

  getNotBilled(type = 'custom') {
    if (type === 'custom') {
      return {
        title: 'Billed?',
        type: 'custom',
        renderComponent: LockBilledComponent,
        filter: {
          type: 'list',
          config: {
            selectText: 'All',
            list: [
              { value: true, title: 'No' },
              { value: false, title: 'Yes' },
            ],
          },
        },
        onComponentInitFunction: instance => {
          instance.billedChanged.subscribe((newStatus: boolean) => {
            this.subjectService.emitWithMessage({
              for: 'AdminLocks',
              value: newStatus,
            });
          });
        },
      };
    } else {
      return {
        title: 'Billed?',
        type: 'string',
        valuePrepareFunction: (cell, row) => (cell ? 'No' : 'Yes'),
        filter: {
          type: 'list',
          config: {
            selectText: 'All',
            list: [
              { value: true, title: 'No' },
              { value: false, title: 'Yes' },
            ],
          },
        },
      };
    }
  }

  getLockInfoShort(renderComponent) {
    return {
      title: 'Lock Name',
      type: 'custom',
      renderComponent: renderComponent,
      filterFunction: (cell: any, search?: string): boolean => {
        return this.lockFilterService.process(search, cell, true);
      },
      compareFunction: this.comparatorService.lockDetailsComparator,
    };
  }

  getLockType(locks) {
    const LockType = {
      title: 'Lock Manufacturer',
      type: 'string',
      editable: false,
      filter: {
        type: 'list',
        config: {
          selectText: 'All',
          list: this.tableSettingsUtil.getLockManufacturerList(locks),
        },
      },
    };
    return LockType;
  }

  getSubtype(locks) {
    return {
      title: 'Lock Type',
      type: 'string',
      editable: false,
      filter: {
        type: 'list',
        config: {
          selectText: 'All',
          list: this.tableSettingsUtil.getLockSubtypes(locks),
        },
      },
      filterFunction(cell: any, search?: string): boolean {
        return !search || search === cell;
      },
    };
  }

  /**
   * Utility method for getting string based column object for smart table
   * with input type filter.
   */
  vanillaColumn(title: string) {
    return {
      title,
      type: 'string',
      editable: false,
      compareFunction: this.comparatorService.stringComparator,
    };
  }

  /**
   * returns permission column settings for showing permission with or without owner filter
   */
  getPermission(showOwner = false) {
    const filterList = [
      { value: 'Admin', title: 'Admin' },
      { value: 'View', title: 'View' },
      { value: 'Maintenance', title: 'Maintenance' },
      { value: 'Restricted', title: 'Restricted' },
      { value: 'OneTime', title: 'One-Time' },
    ];

    if (showOwner) {
      filterList.unshift({ value: 'Owner', title: 'Owner' });
    }

    return {
      title: 'Permission',
      type: 'string',
      editable: false,
      valuePrepareFunction: value => {
        return value === 'OneTime' ? 'One-Time' : value;
      },
      filter: {
        type: 'list',
        config: {
          selectText: 'All',
          list: filterList,
        },
      },
    };
  }

  getUserDetailsComponent(title: string, showAliasComponent: boolean) {
    return {
      title,
      type: 'custom',
      editable: false,
      valuePrepareFunction: (cell, row) => {
        const value = showAliasComponent ? row : cell;
        return value;
      },
      renderComponent: showAliasComponent ? UserAliasComponent : UserDetailsItemComponent,
      filterFunction: (cell: any, search?: string): boolean => {
        return this.userFilterService.process(search, cell);
      },
      compareFunction: this.comparatorService.usersComparator,
    };
  }

  getTimezoneAwareDateTime(title: string, pastFilter?, nullString?) {
    return {
      title,
      type: 'custom',
      editable: false,
      renderComponent: TimezoneAwareDateTimeComponent,
      onComponentInitFunction: instance =>
        nullString ? (instance.unknownText = nullString) : null,
      filter: {
        type: 'list',
        config: {
          selectText: 'Anytime',
          list: this.tableSettingsUtil.getExpiresOnFilterList(),
        },
      },
      filterFunction: (cell: any, search?: string): boolean => {
        return this.dateFilterService.process(search, cell, pastFilter, nullString);
      },
      compareFunction: this.comparatorService.stringComparator,
    };
  }

  getTotalHubLocks() {
    return {
      title: 'Available Pegs',
      type: 'string',
      valuePrepareFunction: (cell, row) => {
        return cell.length.toString();
      },
      filterFunction: (cell, search) =>
        this.tableSettingsUtil.hubAvailableLocksFilterFunction(cell, search),
    };
  }

  getHubViewLocksButton(isAdmin) {
    return {
      title: 'View Pegs',
      type: 'custom',
      valuePrepareFunction: (cell, row) => row,
      renderComponent: HubViewLocksButtonComponent,
      onComponentInitFunction: instance => (instance.isAdmin = isAdmin),
      filter: false,
    };
  }

  countArray(title) {
    return {
      title,
      type: 'html',
      valuePrepareFunction: (cell, row) => `<div class="text-center">${cell.length}</div>`,
      filter: false,
    };
  }
}
