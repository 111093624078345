import { Injectable } from '@angular/core';
import { TableColumnProviderService } from './table-column-provider.service';

@Injectable({
  providedIn: 'root',
})
export class TableSettingsProviderService {
  constructor(private tableColumns: TableColumnProviderService) {}

  getCommonSettings(
    multiSelect: boolean = true,
    editAllowed: boolean = true,
    deleteAllowed = false,
    numPages: number = 100,
  ): any {
    const settings: any = {};
    if (multiSelect) settings.selectMode = 'multi';
    settings.mode = 'external';
    settings.actions = {
      edit: false,
      add: false,
      delete: false,
      position: 'right',
      columnTitle: '',
    };
    if (editAllowed) {
      settings.actions.edit = true;
      settings.edit = {
        editButtonContent: '<i class="nb-edit"></i>',
        saveButtonContent: '<i class="nb-checkmark"></i>',
        cancelButtonContent: '<i class="nb-close"></i>',
        confirmSave: true,
      };
    }
    if (deleteAllowed) {
      // for some reason delete doesn't like mode
      delete settings.mode;
      settings.actions.delete = true;
      settings.delete = {
        deleteButtonContent: '<i class="ion-ios-trash-outline"></i>',
        confirmDelete: true,
      };
    }
    settings.pager = {
      perPage: numPages,
    };

    return settings;
  }

  getLocksPageTableSettings(locks, multiSelect = true, editAllowed = true) {
    const settings = this.getCommonSettings(multiSelect, editAllowed);
    settings.columns = this.tableColumns.getLocksPageColumns(locks);
    return settings;
  }

  getLockStatusTableSettings(locks) {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getLockStatusColumns(locks);
    settings.hideSubHeader = true;
    settings.noDataMessage =
      'HD Padlocks not found or No HD Padlocks have Unlocked or Shackle Removed Status!';
    return settings;
  }

  getHubsPageTableSettings(isKeyrack) {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getHubsPageColumn(isKeyrack);
    return settings;
  }

  getAdminHubsPageTableSettings(isKeyrack, hubId) {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getAdminHubsPageColumn(isKeyrack, hubId);
    return settings;
  }
  getHubsTableViewSetting(isAdmin) {
    const settings = this.getCommonSettings(false, isAdmin);
    settings.columns = this.tableColumns.getHubsTableViewColumns(isAdmin);
    return settings;
  }

  getPermissionPageSettings(showMultiselect, hideFilter) {
    const settings = this.getCommonSettings(showMultiselect, false);
    settings.columns = this.tableColumns.getPermissionPageColumns();
    settings.hideSubHeader = hideFilter;
    return settings;
  }

  getGroupPermissionSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getGroupPermissionColumns();
    return settings;
  }

  getCodesPageSettings(isPastData: boolean) {
    const settings = this.getCommonSettings(false, false, !isPastData);
    settings.columns = this.tableColumns.getCodesPageColumns(isPastData);
    return settings;
  }

  getActivityPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getActivityPageColumns();
    settings.hideSubHeader = true;
    return settings;
  }

  getAdminActivityPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getAdminActivityPageColumns();
    settings.hideSubHeader = true;
    return settings;
  }

  getGroupActivitySettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getGroupActivityColumns();
    settings.hideSubHeader = true;
    return settings;
  }

  getTeamActivitySettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getTeamActivityColumns();
    settings.hideSubHeader = true;
    return settings;
  }

  getTeamMembersTableSettings() {
    const settings = this.getCommonSettings(false, false, true);
    settings.columns = this.tableColumns.getTeamMembersColumns();
    return settings;
  }

  getGroupLocksTableSettings(locks) {
    const settings = this.getCommonSettings(false, false, true);
    settings.columns = this.tableColumns.getGroupLocksPageColumns(locks);
    return settings;
  }

  getGroupTableViewSettings() {
    const settings = this.getCommonSettings(false, false, false);
    settings.columns = this.tableColumns.getGroupTableViewColumns();
    return settings;
  }

  getTeamTableViewSettings() {
    const settings = this.getCommonSettings(false, false, false);
    settings.columns = this.tableColumns.getTeamTableViewColumns();
    return settings;
  }

  getApiKeysSettings() {
    const settings = this.getCommonSettings(false, false, true);
    settings.columns = this.tableColumns.getApiKeysColumns();
    return settings;
  }

  getAdminLocksPageSettings(locks) {
    const settings = this.getCommonSettings(false, true);
    settings.columns = this.tableColumns.getAdminLocksPageColumn(locks);
    return settings;
  }

  getAdminLocksPoolPageSettings(locks) {
    const settings = this.getCommonSettings(false, true);
    settings.columns = this.tableColumns.getAdminLocksPoolPageColumn(locks);
    return settings;
  }

  getAdminPermissionPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getAdminPermissionPageColumn();
    settings.hideSubHeader = true;
    return settings;
  }
  getAdminActivationPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getAdminActivationPageColumn();
    return settings;
  }

  getAdminRegistrationPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getAdminRegistrationPageColumn();
    return settings;
  }

  getAdminCodesPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.columns = this.tableColumns.getAdminCodesPageColumn();
    return settings;
  }

  getAdminBulkActivationPageSettings() {
    const settings = this.getCommonSettings(false, false);
    settings.pager = {
      display: false,
    };
    settings.columns = this.tableColumns.getAdminBulkActivatioPageColumn();
    return settings;
  }
}
