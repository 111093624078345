import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lockvue-export-data-button',
  templateUrl: './export-data-button.component.html',
  styleUrls: ['./export-data-button.component.scss'],
})
export class ExportDataButtonComponent {
  // Event for export data as CSV
  @Output() exportCSV: EventEmitter<any>;

  constructor() {
    this.exportCSV = new EventEmitter();
  }

  onExportAsCSV() {
    this.exportCSV.emit();
  }
}
