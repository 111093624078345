import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lockvue-lock-stat-item',
  templateUrl: './lock-stat-item.component.html',
  styleUrls: ['./lock-stat-item.component.scss'],
})
export class LockStatItemComponent {
  constructor() {}
}
