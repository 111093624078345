import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PermissionsService, PermissionDetailsV2 } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-base-confirmation-with-permissions',
  templateUrl: './base-confirmation-with-permissions.component.html',
  styleUrls: ['./base-confirmation-with-permissions.component.scss'],
})
export class BaseConfirmationWithPermissionsComponent {
  constructor(
    protected ref: NbDialogRef<ConfirmDialogComponent>,
    private permissionsService: PermissionsService,
  ) {}

  // Cancels / closes the confirmation dialog
  cancel() {
    this.ref.close(false);
  }

  // Closes the dialog with confirmation set to true.
  // Also revokes all permissions if the revokePermissions flag was set to true
  submit(revokePermissions: boolean, permissions: PermissionDetailsV2[]) {
    if (revokePermissions) {
      this.performPermissionRevocation(permissions);
    } else {
      this.ref.close(true);
    }
  }

  // Revokes given permissions before closing the confirmation dialog
  performPermissionRevocation(permissions: PermissionDetailsV2[]) {
    if (permissions && permissions.length > 0) {
      const totalPermissions = permissions.length;
      let processedPermissions = 0;
      permissions.forEach(permission => {
        this.permissionsService.permissionsRevoke(permission.PermissionId).subscribe(result => {
          ++processedPermissions;
          if (processedPermissions >= totalPermissions) {
            this.ref.close(true);
          }
        });
      });
    }
    this.ref.close(true);
  }
}
