<div class="lock-stats-container">
  <div *ngFor="let item of value">
    <nb-card
      class="lock-stat-item"
      [nbPopover]="lockTypesStats"
      [nbPopoverContext]="item"
      nbPopoverPlacement="bottom">
      <div class="icon-container">
        <img
          src="https://cdn.lockvue.com/manufacturer/{{ item.manufacturer }}.svg"
          title="{{ item.manufacturer }}" />
      </div>

      <div class="details pl-2 pr-2">
        <div class="title h4 text-center">{{ item.total }}</div>
        <div class="status paragraph-2">{{ item.manufacturer }}</div>
      </div>
    </nb-card>
  </div>
</div>

<ng-template #lockTypesStats let-stats>
  <div class="p-2 subtype-item-container">
    <div *ngFor="let item of stats.subtypes">
      <nb-card class="subtype-item">
        <div class="icon-container">
          <img
            src="https://cdn.lockvue.com/locks/{{ stats.manufacturer | lowercase }}_{{
              item.key | lowercase
            }}_green.svg"
            title="{{ item.manufacturer }} {{ item.key }}" />
        </div>

        <div class="details">
          <div class="title h5">{{ item.value }}</div>
          <div class="status paragraph-2">{{ item.key }}</div>
        </div>
      </nb-card>
    </div>
  </div>
</ng-template>
