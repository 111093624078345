import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ngx-search-input',
  styleUrls: ['./search-input.component.scss'],
  template: `
    <i class="control-icon ion ion-ios-search" (click)="showInput()"></i>
    <input
      placeholder="{{ placeholder }}"
      #input
      [class.hidden]="!isInputShown"
      (blur)="hideInput()"
      (input)="onInput($event)"
      [(ngModel)]="searchString" />
  `,
})
export class SearchInputComponent {
  @ViewChild('input', { static: true }) input: ElementRef;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeholder: string;

  searchString = '';
  isInputShown = false;

  showInput() {
    this.isInputShown = true;
    this.input.nativeElement.focus();
  }

  hideInput() {
    this.isInputShown = false;
  }

  onInput(val: string) {
    this.search.emit(this.searchString);
  }
}
