import { Injectable } from '@angular/core';
import { NbToastrConfig, NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessagesService {
  constructor(private toastrService: NbToastrService) {}

  private config: Partial<NbToastrConfig> = {
    duration: 5000,
  };

  showSuccess(title: string, message: string) {
    this.toastrService.success(message, title, this.config);
  }

  showError(title: string, message: string, error?: any) {
    if (error) {
      // Modify message to include error details
    }
    this.toastrService.danger(message, title, this.config);
  }

  showWarning(title: string, message: string) {
    this.toastrService.warning(message, title, this.config);
  }
}
