import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';
import { RemoveLockFromGroupConfirmationComponent } from '../common/remove-lock-from-group-confirmation/remove-lock-from-group-confirmation.component';
import { LockGroupDetailsUIModel } from '../models/LockGroupDetailsUIModel';
import { LockDetailsModel } from '../lockvue-ng-sdk';
import { TeamDetailsUIModel } from '../models/TeamDetailsUIModel';
import { RemoveMemberFromTeamConfirmationComponent } from '../common/remove-member-from-team-confirmation/remove-member-from-team-confirmation.component';
import { RemoveGroupConfirmationComponent } from '../common/remove-group-confirmation/remove-group-confirmation.component';
import { RemoveTeamConfirmationComponent } from '../common/remove-team-confirmation/remove-team-confirmation.component';
import { DownloadConfirmationComponent } from '../common/download-confirmation/download-confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  constructor(private dialogService: NbDialogService) {}

  open(
    text?: string,
    title?: string,
    confirmButtonText?: string,
    cancelButtonText?: string,
  ): Observable<boolean> {
    const data: any = {};

    if (text) data.value = text;
    if (title) data.title = title;
    if (confirmButtonText) data.confirmButtonText = confirmButtonText;
    if (cancelButtonText) data.cancelButtonText = cancelButtonText;

    return this.dialogService.open(ConfirmDialogComponent, { context: data }).onClose;
  }

  openRemoveLockFromGroup(
    group: LockGroupDetailsUIModel,
    lock: LockDetailsModel,
  ): Observable<boolean> {
    // find group permissions associated with current lock
    const permissions = group.AssociatedPermissions.filter(
      x => x.LockDetails && x.LockDetails.Id === lock.Id,
    );

    const data: any = {};
    data.group = group;
    data.lock = lock;
    data.permissions = permissions;

    return this.dialogService.open(RemoveLockFromGroupConfirmationComponent, { context: data })
      .onClose;
  }

  openRemoveMemberFromTeam(team: TeamDetailsUIModel, memberUserId: string): Observable<boolean> {
    // find team permissions granted to the user
    const permissions = team.AssociatedPermissions.filter(
      x => x.UserId && x.UserId.toLowerCase() === memberUserId.toLowerCase(),
    );

    const data: any = {};
    data.team = team;
    data.memberUserId = memberUserId;
    data.permissions = permissions;

    return this.dialogService.open(RemoveMemberFromTeamConfirmationComponent, { context: data })
      .onClose;
  }

  openRemoveGroup(group: LockGroupDetailsUIModel): Observable<boolean> {
    const data: any = {};
    data.group = group;
    data.permissions = group.AssociatedPermissions;
    return this.dialogService.open(RemoveGroupConfirmationComponent, { context: data }).onClose;
  }

  openRemoveTeam(team: TeamDetailsUIModel): Observable<boolean> {
    const data: any = {};
    data.team = team;
    data.permissions = team.AssociatedPermissions;
    return this.dialogService.open(RemoveTeamConfirmationComponent, { context: data }).onClose;
  }

  openConfirmDownload(approxPages: number): Observable<boolean> {
    return this.dialogService.open(DownloadConfirmationComponent, { context: { approxPages } })
      .onClose;
  }
}
