import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fakeArray',
})
export class FakeArrayPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {
    return Array(value);
  }
}
