import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbThemeService } from '@nebular/theme';
import { LocalStorageKeys } from '../../../services/constants/LocalStorageKeys';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'lockvue-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public errors: string[] = [];
  public messages: string[] = [];
  public email: string;
  public password: string;
  public alias: string;
  public submitted: boolean = false;
  public showAlias = false;
  private readonly redirectDelay_ms = 500;

  constructor(
    protected router: Router,
    private http: HttpClient,
    private configProvider: LockvueConfigProviderService,
    private authService: NbAuthService,
    private themeService: NbThemeService,
    private localStorageService: LocalStorageService,
  ) {}

  public ngOnInit() {
    const theme = this.configProvider.getTheme();
    this.themeService.changeTheme(theme);
    this.authService.isAuthenticated().subscribe(authenticated => {
      if (authenticated) {
        console.log('authenticated', authenticated);
        this.authService.authenticate('fusion-auth').subscribe(console.log);
      }
    });
  }

  public login() {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    // found name from app.module.ts NbPasswordAuthStrategy.setup()
    this.authService
      .authenticate('email', { email: this.email, password: this.password })
      .subscribe(result => {
        this.submitted = false;

        if (result.isSuccess()) {
          this.messages = result.getMessages();
          // only set alias when alias is entered by the user.
          // remove alias from local storage when user login without using a shared account.
          if (this.showAlias && this.alias !== undefined) {
            this.localStorageService.setItem(LocalStorageKeys.Alias, this.alias);
          }
          if (!this.showAlias) {
            this.localStorageService.removeItem(LocalStorageKeys.Alias);
          }
        } else {
          if (result.getResponse().status === 400) {
            this.errors = result.getErrors();
          }
          if (result.getResponse().status === 403) {
            this.sendVerificationMail();
          }
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay_ms);
        }
      });
  }

  private sendVerificationMail() {
    const url =
      this.configProvider.getAuthServerUrl() +
      '/Account/request-email-confirmation?clientId=' +
      this.configProvider.getClientId();
    this.http
      .post(url, {
        Email: this.email,
        Password: this.password,
      })
      .subscribe(
        result => {
          this.errors.push(
            'Verification Email has been re-sent. Please verify your account first and then login.',
          );
        },
        error => {
          if (error.status === 400) {
            this.errors.push('Invalid login attempt.');
          }
          if (error.status === 403) {
            this.errors.push(
              'Your account is locked out due to security reasons! Please contact admin',
            );
          }
        },
      );
  }

  loginWithFA() {
    console.log('Login With FA');
    this.authService.authenticate('fusion-auth').subscribe(console.log);
  }
}
