import { Component, Input, OnInit } from '@angular/core';
import { LockActivityModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-transactional-telemetry-item',
  templateUrl: './transactional-telemetry-item.component.html',
  styleUrls: ['./transactional-telemetry-item.component.scss'],
})
export class TransactionalTelemetryItemComponent implements OnInit {
  constructor() {}

  @Input() event: LockActivityModel;
  requestValue;

  ngOnInit(): void {
    if (this.event.EventAction === 'Request')
      this.requestValue = this.splitCamelCase(this.event.Value);
  }

  getLatitude() {
    if (this.event.Value && this.event.Value.includes(',')) {
      const result = this.event.Value.split(',')[0];
      return parseFloat(result);
    }
    return null;
  }

  getLongitude() {
    if (this.event.Value && this.event.Value.includes(',')) {
      const result = this.event.Value.split(',')[1];
      return parseFloat(result);
    }
    return null;
  }

  private splitCamelCase(string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
