import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { NbWindowRef, NbWindowService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HubDetailsModel } from '../../lockvue-ng-sdk';
import { SubjectService } from '../../services/subject.service';
import { HubDetailWindowComponent } from '../hub-detail-window/hub-detail-window.component';

@Component({
  selector: 'lockvue-hub-view-locks-button',
  templateUrl: './hub-view-locks-button.component.html',
  styleUrls: ['./hub-view-locks-button.component.scss'],
  // for removing circular dependencies that were introduced because of table settings restructuring.
  providers: [
    { provide: HubDetailWindowComponent, useExisting: forwardRef(() => HubDetailWindowComponent) },
  ],
})
export class HubViewLocksButtonComponent implements OnDestroy {
  @Input() value: HubDetailsModel;
  // to propogate it further and show buttons and stuff based on whether the user is admin or not; by default false;
  @Input() isAdmin: boolean = false;
  private windowRef: NbWindowRef;

  get terminology() {
    return this.value.HubType.includes('KeyRack') ? 'Pegs' : 'Locks';
  }

  constructor(private windowService: NbWindowService, private subjectService: SubjectService) {}
  private destroy$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    if (this.windowRef) this.windowRef.close();
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDetailWindow() {
    this.windowRef = this.windowService.open(HubDetailWindowComponent, {
      title: `Locks in ${this.value.Name}`,
      context: { hub: this.value, isAdmin: this.isAdmin },
    });
    this.windowRef.onClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.subjectService.emitWithMessage({ for: 'refreshHubs', value: true });
    });
  }
}
