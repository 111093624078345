import { Component, OnInit, Input } from '@angular/core';
import { UserDetailsLimited } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-last-unlock',
  templateUrl: './last-unlock.component.html',
  styleUrls: ['./last-unlock.component.scss'],
})
export class LastUnlockComponent {
  @Input() lastUnlockedBy?: UserDetailsLimited;
  @Input() lastUnlockedAtUtc?: Date;

  constructor() {}
}
