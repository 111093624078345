import { Injectable } from '@angular/core';
import { LockDetailsModel } from '../lockvue-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class LockFilterService {
  constructor() {}

  public process(
    filter: string,
    lockDetails: LockDetailsModel,
    searchSerialNumber?: boolean,
  ): boolean {
    // No filter restrictions apply
    if (!filter) {
      return true;
    }

    // Check if the search text is found in lock display name
    if (
      lockDetails &&
      lockDetails.DisplayName &&
      lockDetails.DisplayName.toLowerCase().indexOf(filter.toLowerCase()) > -1
    ) {
      return true;
    }

    // Check if search text is found in lock serial number
    if (
      searchSerialNumber &&
      lockDetails &&
      lockDetails.UID &&
      lockDetails.UID.toLowerCase().indexOf(filter.toLowerCase()) > -1
    ) {
      return true;
    }

    // No match found
    return false;
  }
}
