<div class="table-container">
  <table>
    <thead>
      <tr>
        <th></th>
        <th *ngFor="let col of cols | fakeArray; let j = index">{{ j + 1 }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows | fakeArray; let r = index">
        <th>{{ r + 1 }}</th>
        <td *ngFor="let col of cols | fakeArray; let c = index">
          <lockvue-hub-lock-icon-view
            [currentRow]="r + 1"
            [currentCol]="c + 1"
            [lock]="getLockAtPosition(r + 1, c + 1)"
            [isAdmin]="isAdmin"></lockvue-hub-lock-icon-view>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- <div *ngIf="lastHeartbeat">
  <span class="float-right mr-2 caption">This view was updated {{ lastHeartbeat | amTimeAgo }}.</span>
</div> -->
