import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';

@Component({
  selector: 'lockvue-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss'],
})
export class EmailConfirmedComponent {
  constructor(private configService: LockvueConfigProviderService) {}

  get clientName(): string {
    return this.configService.getDWLClientName();
  }
}
