import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ProfileService, TeamsService, UserModel } from '../../lockvue-ng-sdk';
import { Subject } from 'rxjs';
import { NbStepperComponent, NbTagInputAddEvent, NbTagInputDirective } from '@nebular/theme';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lockvue-new-team',
  templateUrl: './new-team.component.html',
  styleUrls: ['./new-team.component.scss'],
})
export class NewTeamComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private next$ = new Subject();
  private memberToAdd;
  private newMembersAdded = 0;

  teamName: string;
  teamDescription: string;
  teamId: string;
  inProgress: boolean;
  currentTeamMembers: Set<string> = new Set([]);
  profile: UserModel;

  @ViewChild('stepper', { static: true }) stepperComponent: NbStepperComponent;
  @ViewChild(NbTagInputDirective, { read: ElementRef }) tagInput: ElementRef<HTMLInputElement>;

  constructor(
    private teamsService: TeamsService,
    private notificationsService: NotificationMessagesService,
    private userProfile: ProfileService,
  ) {}

  ngOnInit() {
    this.userProfile
      .profileMy()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(profile => (this.profile = profile));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createTeam() {
    this.inProgress = true;
    this.teamsService
      .teamsAdd({
        Name: this.teamName ? this.teamName.trim() : '',
        Description: this.teamDescription ? this.teamName.trim() : '',
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.inProgress = false;
          this.teamId = result;
          this.notificationsService.showSuccess('Success', 'Team created successfully.');
        },
        error => {
          this.inProgress = false;
          this.notificationsService.showError(
            'Error',
            'Team creation failed. Error: ' + (error ? error.message : 'Unknown'),
          );
          this.stepperComponent.reset();
        },
      );
  }

  addSelectedMember(member: NbTagInputAddEvent) {
    if (member.value === this.profile.UserId) {
      this.notificationsService.showError('User Error!', "You can't add yourself to the team.");
      this.tagInput.nativeElement.value = '';
      return;
    }
    if (
      member.value &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(member.value)
    ) {
      if (this.currentTeamMembers.has(member.value)) {
        this.notificationsService.showError(
          'User Exists!',
          'The user you have entered already exist in the team.',
        );
        this.tagInput.nativeElement.value = '';
      } else {
        return this.addMemberToTeam(member);
      }
    } else {
      if (this.newMembersAdded > 0 && this.memberToAdd === null) {
        this.stepperComponent.next();
      } else {
        this.notificationsService.showError('Invalid Email', 'Please enter a valid email');
      }
    }
  }

  private addMemberToTeam(member: NbTagInputAddEvent) {
    this.inProgress = true;
    this.memberToAdd = null;
    this.teamsService
      .teamsAddMember({
        TeamId: this.teamId,
        UserId: member.value,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.inProgress = false;
          // only if the function is called from next()
          if (member.input === null) {
            this.next$.next(true);
          }
          this.currentTeamMembers.add(member.value);
          this.tagInput.nativeElement.value = '';
          this.newMembersAdded++;
          this.notificationsService.showSuccess(
            'Success',
            'Member was successfully added to the team.',
          );
        },
        error => {
          this.next$.next(false);
          this.inProgress = false;
          this.notificationsService.showError(
            'Error',
            'Error while adding member to the team: ' + (error ? error.message : 'Unknown'),
          );
        },
      );
  }

  next() {
    // if user clicks on next than it means he want's to go to next step.
    // So we only subscribe to the next$ here.
    this.next$.pipe(takeUntil(this.unsubscribe$)).subscribe(next => {
      if (next) {
        this.stepperComponent.next();
      }
    });
    // to handle any previous teams that have no members
    if (this.currentTeamMembers.size === 0) {
      this.notificationsService.showError('No Member!', 'Add atleast one member in the team!');
    } else {
      this.addSelectedMember({ input: null, value: this.memberToAdd });
    }
  }
}
