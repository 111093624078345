import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lockvue-user-alias',
  templateUrl: './user-alias.component.html',
  styleUrls: ['./user-alias.component.scss'],
})
export class UserAliasComponent implements OnInit, OnChanges {
  @Input() value: any;
  public showUserDetails = true;

  constructor() {}
  ngOnInit(): void {
    this.showUserDetails = !this.isAliasTruthy(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showUserDetails = !this.isAliasTruthy(changes.value.currentValue);
  }

  private isAliasTruthy(row) {
    return (
      row['UserAlias'] &&
      row['UserAlias'] !== null &&
      row['UserAlias'] !== undefined &&
      row['UserAlias'] !== ''
    );
  }
}
