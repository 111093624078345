import { Component, OnInit } from '@angular/core';
import { LockActivationModel } from '../../lockvue-ng-sdk';
import { LockActivationService } from '../../services/lock-activation.service';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { UmamiService } from '../../services/umami.service';

@Component({
  selector: 'lockvue-lock-activation',
  templateUrl: './lock-activation.component.html',
  styleUrls: ['./lock-activation.component.scss'],
})
export class LockActivationComponent {
  newLockName: string;
  newLockSN: string;
  requestWIP: boolean;

  constructor(
    private lockActivationService: LockActivationService,
    private notificationsService: NotificationMessagesService,
    private umami: UmamiService,
  ) {}

  activate() {
    this.requestWIP = true;
    this.lockActivationService
      .activate({
        DisplayName: this.newLockName.trim(),
        SerialNumber: this.newLockSN.trim(),
      } as LockActivationModel)
      .subscribe(
        result => {
          this.umami.track('lock-activated', {
            DisplayName: this.newLockName,
            SerialNumber: this.newLockSN,
          });
          this.requestWIP = false;
          this.notificationsService.showSuccess('Success', 'Your lock was successfully activated.');

          // reset inputs
          this.newLockName = '';
          this.newLockSN = '';
        },
        error => {
          this.requestWIP = false;
          this.notificationsService.showError(
            'Error',
            'There was an error while activating your lock.',
            error,
          );
        },
      );
  }
}
