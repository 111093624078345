import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'lockvue-download-confirmation',
  templateUrl: './download-confirmation.component.html',
  styleUrls: ['./download-confirmation.component.css'],
})
export class DownloadConfirmationComponent {
  approxPages: number;
  loading = false;

  constructor(private ref: NbDialogRef<DownloadConfirmationComponent>) {}

  close(download: boolean) {
    this.loading = true;
    // without setTimeout, spinner won't be displayed.
    setTimeout(() => this.ref.close(download), 0);
  }
}
