<span *ngIf="value; else deleted"
  >{{ value.Name }}
  <span (click)="showLocks()" class="small text-muted link link-text">(View Members)</span></span
>

<ng-template #deleted>
  <span class="text-muted"
    >Deleted Team <span class="small">({{ rowData.ResourceId }})</span></span
  >
</ng-template>
