import { Component, OnDestroy, ViewChild, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';
import { NbStepperComponent } from '@nebular/theme';
import { LockGroupsService } from '../../lockvue-ng-sdk';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { takeUntil } from 'rxjs/operators';
import { LockGroupDetailsUIModel } from '../../models/LockGroupDetailsUIModel';

@Component({
  selector: 'lockvue-lock-group-edit',
  templateUrl: './lock-group-edit.component.html',
  styleUrls: ['./lock-group-edit.component.scss'],
})
export class LockGroupEditComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  inProgress: boolean;
  selectedLocks: Array<{ Name: string; Id: string; Removable: boolean }>;

  copyPermissions: boolean; // whether to auto grant permission
  copyPermissionsFrom: string; // lock-id of the lock where permissions should be copied from

  @Input() value: LockGroupDetailsUIModel;

  @ViewChild('stepper', { static: true }) stepperComponent: NbStepperComponent;

  constructor(
    private lockGroupsService: LockGroupsService,
    private notificationsService: NotificationMessagesService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleCopyPermissionsCheckbox(checked: boolean) {
    this.copyPermissions = checked;

    // Select first user by default
    if (checked && !this.copyPermissionsFrom && this.value?.Locks?.length > 0) {
      this.copyPermissionsFrom = this.value?.Locks[0].Id;
    }
  }

  editLockGroup() {
    this.inProgress = true;
    this.lockGroupsService
      .lockGroupsEdit({
        Id: this.value.Id,
        Name: this.value.Name,
        Description: this.value.Description,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.inProgress = false;
          this.notificationsService.showSuccess('Success', 'Lock Group updated successfully.');
        },
        error => {
          this.inProgress = false;
          this.notificationsService.showError(
            'Error',
            'Lock Group modification failed. Error: ' + (error ? error.message : 'Unknown'),
          );
          this.stepperComponent.reset();
        },
      );
  }

  addSelectedLock() {
    if (!this.selectedLocks || this.selectedLocks.length === 0) {
      this.notificationsService.showError(
        'No locks added',
        'No New Locks were added to the group.',
      );
    } else {
      this.inProgress = true;
      const locks = this.selectedLocks.filter(s => !this.value.Locks.includes(s));
      locks.forEach(l => {
        this.lockGroupsService
          .lockGroupsAddLockToGroup({
            GroupId: this.value.Id,
            LockId: l.Id,
            AutoGrantPermissions: true,
            AutoGrantPermissionsFromLockId: this.value?.Locks[0]?.Id,
          })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            result => {
              this.inProgress = false;
              this.notificationsService.showSuccess(
                'Success',
                'Lock was successfully added to the lock group.',
              );
              const numPermissions = result ? '' + result.length : 'No';
              this.notificationsService.showSuccess(
                'Permission Automation',
                numPermissions + ' permissions were automatically granted.',
              );
              this.stepperComponent.next();
            },
            error => {
              this.inProgress = false;
              this.notificationsService.showError(
                'Error',
                'Error while adding lock to the lock group: ' + (error ? error.message : 'Unknown'),
              );
              this.stepperComponent.next();
            },
          );
      });
    }
  }

  selectedLocksChange($event: Set<{ Name: string; Id: string; Removable: boolean }>) {
    this.selectedLocks = [...$event].filter(v => v.Removable === true);
  }
}
