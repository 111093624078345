import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LockInHubDetailsModel } from '../../lockvue-ng-sdk';
import { LockIconHelperService } from '../../services/lock-icon-helper.service';

@Component({
  selector: 'lockvue-hub-lock-icon-view',
  templateUrl: './hub-lock-icon-view.component.html',
  styleUrls: ['./hub-lock-icon-view.component.scss'],
})
export class HubLockIconViewComponent {
  private _lock: LockInHubDetailsModel;

  @Input() set lock(value: LockInHubDetailsModel) {
    this._lock = value;
    this.icon = this.lockIconHelperServcice.getKRIcon(value, this.currentCol);
  }

  get lock() {
    return this._lock;
  }

  @Input() currentCol: number;
  @Input() currentRow: number;
  @Input() isAdmin: boolean;

  icon: string;

  constructor(private router: Router, private lockIconHelperServcice: LockIconHelperService) {}

  redirect(route: string) {
    this.router.navigate([route], { queryParams: { SerialNumber: this.lock.LockSerialNumber } });
  }
}
