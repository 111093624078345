<nb-list>
  <nb-list-item *ngIf="hub.HubType.includes('KeyRack')">
    <b>Total Rows:</b>&nbsp;{{ hub.TotalRows }}
  </nb-list-item>
  <nb-list-item
    ><b>Available {{ terminology }}:</b>&nbsp;{{ hub.Locks.length }}</nb-list-item
  >
  <nb-list-item><b>UID:</b>&nbsp;{{ hub.UID }}</nb-list-item>
  <nb-list-item><b>HWAddress:</b>&nbsp;{{ hub.HWAddress }}</nb-list-item>
  <nb-list-item
    ><b>Last Heartbeat:</b>&nbsp;
    <lockvue-timezone-aware-date-time
      [value]="hub.LastHeartbeatUtc"
      unkownText="Unknown"></lockvue-timezone-aware-date-time>
  </nb-list-item>
</nb-list>
