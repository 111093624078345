<div [ngSwitch]="value" class="d-inline-block">
  <lockvue-battery-level *ngSwitchCase="'Battery'" [value]="rowData.Value"></lockvue-battery-level>
  <div *ngSwitchCase="'Lock_Status'">
    Lock Status <span class="small text-muted">({{ rowData.Value.replace('_', ' ') }})</span>
  </div>
  <div *ngSwitchCase="'Request'">
    Request <span class="small text-muted">({{ requestValue }})</span>
  </div>
  <span *ngSwitchDefault>{{ value.replace('_', ' ') }}</span>

  <small *ngIf="rowData.Offline">&nbsp;(Offline)</small>
</div>
