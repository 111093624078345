import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MapService } from '../../services/map.service';
import { NbThemeService } from '@nebular/theme';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lockvue-embedded-map',
  templateUrl: './embedded-map.component.html',
  styleUrls: ['./embedded-map.component.scss'],
})
export class EmbeddedMapComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() latitude: number;
  @Input() longitude: number;

  @Input() showLargeMapText: boolean = false;

  mapStyle: any;

  constructor(private mapService: MapService, private themeService: NbThemeService) {}

  ngOnInit() {
    this.mapStyle = this.mapService.getMapStyle(this.themeService.currentTheme);
    this.themeService
      .onThemeChange()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((theme: any) => {
        this.mapStyle = this.mapService.getMapStyle(theme.name);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMapUrl() {
    // return 'https://www.google.com/maps/place/@' + this.latitude + ',' + this.longitude + '';
    return 'https://maps.google.com?q=' + this.latitude + ',' + this.longitude;
  }
}
