<agm-map
  [latitude]="latitude"
  [longitude]="longitude"
  [styles]="mapStyle"
  [fullscreenControl]="true"
  [streetViewControl]="false"
  [zoomControl]="true"
  [zoom]="10">
  <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
</agm-map>
<a *ngIf="showLargeMapText" [attr.href]="getMapUrl()" class="float-right" target="_blank">
  View on a larger map.
</a>
