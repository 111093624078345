import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { FusionAuthStrategy } from './strategies/fusion-auth.strategy';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: 'https://auth.lockvue.net/ad9aa51a-ece0-4fcb-92b9-4b360d1ad0e3',
        redirectUrl: `${window.location.origin}/auth/callback`,
        postLoginRoute: '/dashboard',
        postLogoutRedirectUri: `${window.location.origin}`,
        clientId: '5ee524a3-bc57-45dc-acdc-409a6ff7872c',
        scope: 'openid profile offline_access', // 'openid profile offline_access ' + your scopes
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        triggerAuthorizationResultEvent: true,
        secureRoutes: [environment.apiServerUrl],
      },
    }),
  ],
  exports: [AuthModule],
  providers: [FusionAuthStrategy],
})
export class AuthConfigModule {}
