<h1 id="title" class="title">Register</h1>
<form (ngSubmit)="register()" #form="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="displayFirstName"
      name="displayFirstName"
      #dfn="ngModel"
      type="text"
      id="input-display-name"
      placeholder="First Name"
      [required]="true"
      [status]="dfn.touched && dfn.invalid ? 'danger' : null"
      autofocus />
    <small class="text-danger" *ngIf="dfn.touched && dfn.invalid"> First Name is required! </small>
  </div>
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="displayLastName"
      name="displayLastName"
      #dln="ngModel"
      type="text"
      id="input-display-name"
      placeholder="Last Name"
      [required]="true"
      [status]="dln.touched && dln.invalid ? 'danger' : null"
      autofocus />
    <small class="text-danger" *ngIf="dln.touched && dln.invalid"> Last Name is required! </small>
  </div>
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="email"
      name="email"
      type="email"
      id="input-email"
      pattern=".+@.+\..+"
      placeholder="Email address"
      [required]="true" />
  </div>
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="password"
      name="password"
      type="password"
      id="input-password"
      placeholder="Password"
      [required]="true" />
    <small class="text-muted">
      Passwords must be at least 7 characters in length and must contain an upper case letter, a
      lower case letter and a numeric character.
    </small>
  </div>
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="confirmPassword"
      name="confirm-password"
      type="password"
      id="input-confirm-password"
      placeholder="Confirm Password"
      [required]="true"
      [pattern]="password"
      [status]="confirmPassword && password !== confirmPassword ? 'danger' : null" />
  </div>
  <!-- <div class="form-control-group">
    <input nbInput
        fullWidth
        [(ngModel)]='phoneNumber'
        name="phone-number"
        type="tel"
        #p="ngModel"
        [required]="true"
        pattern="[(\+)*[0-9 ]{8,}"
        id="input-phone-number"
        placeholder="Phone Number"
        [status]="(p.touched && p.invalid) ? 'danger' : null">
        <small class="text-danger" *ngIf="p.touched && p.invalid">
          Phone number is required and should contain at least 8 characters and can not containe alphabets!
        </small>
  </div> -->
  <div class="form-control-group">
    <input
      nbInput
      fullWidth
      [(ngModel)]="company"
      name="company"
      type="text"
      id="input-company"
      [required]="true"
      #c="ngModel"
      [status]="c.touched && c.invalid ? 'danger' : null"
      placeholder="Company" />
    <small class="text-danger" *ngIf="c.touched && c.invalid"> Company Name is required! </small>
  </div>
  <p>
    By selecting 'Create Account' you are indicating that you have read, and agree, to our
    <a href="https://www.lockvue.com/privacy-policy.html" target="_blank">Privacy Policy</a> and
    <a href="https://www.lockvue.com/terms-of-use.html" target="_blank">Terms of Use</a>.
  </p>
  <button
    nbButton
    fullWidth
    status="success"
    [disabled]="
      submitted ||
      !displayFirstName ||
      !displayLastName ||
      !company ||
      !email ||
      !password ||
      password.length < 7 ||
      password !== confirmPassword
    "
    [class.btn-pulse]="submitted">
    Create Account
  </button>
</form>

<section class="another-action" aria-label="Sign in">
  Already have an account? <a class="text-link" routerLink="../login">Log in</a> instead.
</section>
