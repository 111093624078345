import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lockvue-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss'],
})
/**
 * Basic details component contains name and description fields.
 * It can be used for "new" or "edit" components such as lock groups
 * and teams.
 */
export class BasicDetailsComponent {
  private _name: string;
  private _description: string;

  @Input()
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this.nameChange.emit(this._name);
  }

  @Input()
  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
    this.descriptionChange.emit(this._description);
  }

  @Output() nameChange: EventEmitter<string> = new EventEmitter();
  @Output() descriptionChange: EventEmitter<string> = new EventEmitter();

  constructor() {}
}
