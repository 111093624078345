import { Component, Input } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { TeamDetailsModel } from '../../../lockvue-ng-sdk';
import { TeamActivityModel } from '../../models/TeamActivityModel';
import { TeamMemberComponent } from '../team-member/team-member.component';

@Component({
  selector: 'lockvue-team-name',
  templateUrl: './team-name.component.html',
  styleUrls: ['./team-name.component.scss'],
})
export class TeamNameComponent {
  constructor(private windowService: NbWindowService) {}
  @Input() value: TeamDetailsModel;

  @Input() rowData: TeamActivityModel;

  showLocks() {
    this.windowService.open(TeamMemberComponent, {
      title: `Members in ${this.value.Name}`,
      context: { members: this.value.Members, forceNewLine: true },
    });
  }
}
