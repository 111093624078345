<div class="form-group">
  <label for="newLockSN" class="form-control-label">Serial Number</label>
  <input
    type="text"
    class="form-control"
    placeholder="Serial Number"
    id="newLockSN"
    nbInput
    fullWidth
    [(ngModel)]="newLockSN" />
</div>
<div class="form-group">
  <label for="newLockName" class="form-control-label">Name</label>
  <input
    type="text"
    class="form-control"
    placeholder="Lock Display Name"
    id="newLockName"
    nbInput
    fullWidth
    [(ngModel)]="newLockName" />
</div>

<div class="modal-footer">
  <button nbButton type="button" (click)="activate()" [disabled]="requestWIP">
    <span *ngIf="!requestWIP">Activate</span>
    <span *ngIf="requestWIP">Please Wait...</span>
  </button>
</div>
