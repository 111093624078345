import { Component, Input, OnInit } from '@angular/core';
import { LockInHubDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-hub-lock-key-view',
  templateUrl: './hub-lock-key-view.component.html',
  styleUrls: ['./hub-lock-key-view.component.scss'],
})
export class HubLockKeyViewComponent {
  @Input() locks: LockInHubDetailsModel[];
  @Input() rows: number;
  @Input() cols: number;
  @Input() lastHeartbeat: Date;
  @Input() isAdmin: boolean;

  constructor() {}

  getLockAtPosition(row: number, col: number): LockInHubDetailsModel {
    return this.locks.find(value => value.Row === row && value.Column === col);
  }
}
