import { Component, OnInit, Input } from '@angular/core';
import { LockDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-lock-info',
  templateUrl: './lock-info.component.html',
  styleUrls: ['./lock-info.component.scss'],
})
export class LockInfoComponent {
  @Input() value: LockDetailsModel;

  constructor() {}
}
