<div class="permission-details">
  <lockvue-lock-info-short *ngIf="value.LockDetails" [value]="value.LockDetails" [large]="true">
  </lockvue-lock-info-short>
  <h2 *ngIf="!value.LockDetails">
    <i>Unknown Lock</i>
  </h2>
  <div *ngIf="value.LockDetails">
    <span><b>Last Unlock: </b></span>
    <lockvue-last-unlock
      [lastUnlockedAtUtc]="value.LockDetails.LastUnlockedAtUtc"
      [lastUnlockedBy]="value.LockDetails.LastUnlockedBy">
    </lockvue-last-unlock>
  </div>
  <br />
  <div>
    <span><b>Shared By: </b></span>
    <lockvue-user-details-item [value]="value.GrantedBy"></lockvue-user-details-item>
    <i> on </i>
    <lockvue-timezone-aware-date-time [value]="value.GrantedOnUTC">
    </lockvue-timezone-aware-date-time>
  </div>
  <br />
  <div *ngIf="value.ActivationTimeUtc">
    <span><b>Active From: </b></span>
    <lockvue-timezone-aware-date-time [value]="value.ActivationTimeUtc">
    </lockvue-timezone-aware-date-time>
  </div>
  <br />
  <div>
    <span><b>Expires On: </b></span>
    <lockvue-timezone-aware-date-time
      *ngIf="value.DeactivationTimeUtc"
      [value]="value.DeactivationTimeUtc">
    </lockvue-timezone-aware-date-time>
    <span *ngIf="!value.DeactivationTimeUtc">Indefinite</span>
  </div>
</div>
