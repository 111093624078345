import { Component, Input } from '@angular/core';
import { UmamiService } from '../../services/umami.service';

@Component({
  selector: 'ngx-slide-out',
  styleUrls: ['./slide-out.component.scss'],
  templateUrl: './slide-out.component.html',
})
export class SlideOutComponent {
  @Input() open: boolean = false;
  constructor(private umami: UmamiService) {}
  toggleStatistics() {
    if (this.open) {
      this.umami.track('slideout-close');
    } else {
      this.umami.track('slideout-open');
    }
    this.open = !this.open;
  }
}
