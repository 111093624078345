import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';
import { LocksService } from '../../lockvue-ng-sdk';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { UmamiService } from '../../services/umami.service';

@Component({
  selector: 'lockvue-lock-edit',
  templateUrl: './lock-edit.component.html',
  styleUrls: ['./lock-edit.component.scss'],
})
export class LockEditComponent {
  @Input() value: LockDetailsUIModel;
  @Output() lockUpdated: EventEmitter<LockDetailsUIModel>;
  inProgress: boolean;

  constructor(
    private locksService: LocksService,
    private notificationsService: NotificationMessagesService,
    private umami: UmamiService,
  ) {
    this.lockUpdated = new EventEmitter();
  }

  updateLock() {
    this.inProgress = true;
    this.locksService
      .locksEdit({
        LockId: this.value.Id,
        DisplayName: this.value.DisplayName,
        OfflineEnabled: this.value.OfflineEnabled,
      })
      .subscribe(
        result => {
          this.umami.track('lock-modified');
          this.inProgress = false;
          this.notificationsService.showSuccess('Success', 'Lock details updated successfully.');
          this.lockUpdated.emit(this.value);
        },
        error => {
          this.inProgress = false;
          this.notificationsService.showError(
            'Error',
            'There was an error while updating lock details for: ' + this.value.DisplayName,
            error,
          );
        },
      );
  }
}
