<lockvue-lock-info-short *ngIf="value" [value]="value"></lockvue-lock-info-short>
<span *ngIf="!value">
  <span *ngIf="!rowData.Value">NA</span>
  <span *ngIf="rowData.Value">
    <span *ngIf="rowData.Value.split('-').length === 1; else noPermission">
      {{ rowData.Value }}
    </span>
  </span>
</span>
<ng-template #noPermission>
  <span>No Permission</span>
</ng-template>
