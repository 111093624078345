import { Component, OnInit, Input } from '@angular/core';
import { TeamDetailsModel, TeamMemberModel } from '../../lockvue-ng-sdk';
import { TeamMembersListComponent } from '../team-members-list/team-members-list.component';
import { ConfirmDialogService } from '../../services/confirm-dialog.service';

@Component({
  selector: 'lockvue-teams-table',
  templateUrl: './teams-table.component.html',
  styleUrls: ['./teams-table.component.scss'],
})
export class TeamsTableComponent implements OnInit {
  tableSettings: any;
  private _teams: TeamDetailsModel[];

  @Input() set teams(value: TeamDetailsModel[]) {
    this._teams = value;
    this.initTableSettings(); // refresh table settings
  }

  get teams(): TeamDetailsModel[] {
    return this._teams;
  }

  constructor(private confirmDialogService: ConfirmDialogService) {}

  ngOnInit() {
    this.initTableSettings();
  }

  onRowSelect(event: any) {
    // Nothing as of now
  }

  private initTableSettings() {
    this.tableSettings = {
      hideSubHeader: !this._teams || this._teams.length < 2, // hide filter when items are too few
      actions: {
        add: false,
        position: 'right',
        columnTitle: '',
      },
      edit: {
        editButtonContent: '<i class="nb-edit"></i>',
        saveButtonContent: '<i class="nb-checkmark"></i>',
        cancelButtonContent: '<i class="nb-close"></i>',
      },
      delete: {
        deleteButtonContent: '<i class="ion-ios-trash-outline"></i>',
      },
      pager: {
        perPage: 100,
      },
      columns: {
        Name: {
          title: 'Name',
          type: 'string',
        },
        Description: {
          title: 'Description',
          type: 'string',
          width: '30%',
        },
        Members: {
          title: 'Members',
          // editable: false,
          valuePrepareFunction: this.teamMembersRenderFunction,
          filterFunction: this.membersFilterFunction,
          sort: false,
          width: '40%',
          editor: {
            type: 'custom',
            component: TeamMembersListComponent,
          },
        },
      },
    };
  }

  teamMembersRenderFunction(members: TeamMemberModel[]): string {
    let output: string = '';
    members.forEach(member => {
      //  if (!member.IsCreator) {
      if (output.length > 0) {
        output += ', ';
      }
      output += member.UserId;
      //  }
    });
    return output;
  }

  membersFilterFunction(cell: any, search?: string): boolean {
    // Check if any of the members contain search string
    const members = cell as TeamMemberModel[];

    if (!search || !cell) {
      return true;
    }

    for (let i = 0; i < members.length; i++) {
      if (members[i].UserId && members[i].UserId.includes(search)) {
        return true;
      }
    }
    return false;
  }

  onEditConfirm(event) {
    // TODO
    event.setValue(event.newData);
  }

  onDeleteConfirm(event): void {
    this.confirmDialogService
      .open('Are you sure you want to delete this team?')
      .subscribe(result => {
        if (result) {
          event.confirm.resolve();
        } else {
          event.confirm.reject();
        }
      });
  }
}
