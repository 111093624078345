import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LockActivityUIModel } from '../../models/LockActivityUIModel';
import { EventAction, LockActivityModel } from '../../lockvue-ng-sdk';
import { LocalDataSource } from 'ng2-smart-table';
import { TableSettingsProviderService } from '../../services/table-settings-provider.service';

@Component({
  selector: 'lockvue-lock-activity-table',
  templateUrl: './lock-activity-table.component.html',
  styleUrls: ['./lock-activity-table.component.scss'],
})
export class LockActivityTableComponent implements OnInit {
  tableSettings: any;
  private _lockActivity: LockActivityUIModel[] | LockActivityModel[];
  activitySource: LocalDataSource = new LocalDataSource([]);
  selectedActivity: LockActivityUIModel | LockActivityModel;
  _filters: any[];
  _visibleEvents: EventAction[];
  _offlineVisible: boolean;

  @Input() loading = false;

  @Input() showSlideout = false;

  @Input() set activity(value: LockActivityUIModel[] | LockActivityModel[]) {
    this._lockActivity = value;

    // load the activites into activitySource only if visible events are there
    if (this.visibleEvents) {
      this.loadActivities();
    }

    this.setFilter();

    // subscriber does not work if put under onInit()
    this.activitySource.onChanged().subscribe(event => {
      if (event.action === 'filter' || event.action === 'sort') {
        this.activitySource.getFilteredAndSorted().then(data => {
          this.tableFiltered.emit(data);
        });
      }
    });

    this.initTableSettings(); // refresh table settings
  }

  get activity(): LockActivityUIModel[] | LockActivityModel[] {
    return this._lockActivity;
  }

  @Input() set filters(value: any) {
    this._filters = value;
    this.setFilter();
  }

  get filters() {
    return this._filters;
  }

  @Input() set visibleEvents(value: EventAction[]) {
    this._visibleEvents = value;
    if (this._lockActivity) this.loadActivities();
  }

  get visibleEvents() {
    return this._visibleEvents;
  }

  @Input() set offlineVisible(value: boolean) {
    this._offlineVisible = value;
    if (this._lockActivity) this.loadActivities();
  }
  get offlineVisible() {
    return this._offlineVisible;
  }

  @Output() tableFiltered: EventEmitter<LockActivityModel[]> = new EventEmitter();
  @Output() rowSelected: EventEmitter<LockActivityModel> = new EventEmitter();

  constructor(protected tableSettingsProvider: TableSettingsProviderService) {}

  ngOnInit() {
    this.initTableSettings();
  }

  onRowSelect(event: any) {
    this.selectedActivity = event.data;
    this.rowSelected.emit(event.data);
  }

  protected initTableSettings() {
    this.tableSettings = this.tableSettingsProvider.getActivityPageSettings();
  }

  /**
   * loads the activity based on the visible events into the activity source
   * and emits the filtered activity for PDF/CSV Exports.
   */
  private loadActivities() {
    if (this.visibleEvents && this._lockActivity) {
      // We want to add more events to the access events when we want to see the offline events.
      // This is because we loose offline events without this in access events.
      // Simply adding the Locked and Unlock Events to the visibleEvents list creates mess in UI hence this logic.
      const offlineEventsVisibleInAccessEvents = [
        EventAction.Locked,
        EventAction.Unlocked,
        EventAction.Battery,
        EventAction.ShackleRemoved,
      ];
      const activities = this._lockActivity.filter(
        a =>
          this.visibleEvents.includes(a.EventAction) ||
          (this.offlineVisible &&
            a.Offline &&
            offlineEventsVisibleInAccessEvents.includes(a.EventAction)),
      );
      this.activitySource.load(activities);
      this.tableFiltered.emit(activities);
    }
  }

  /**
   * Sets filters if there are any
   */
  setFilter() {
    if (this._filters && this._filters.length > 0) {
      this.activitySource.setFilter(this._filters);
    } else {
      // Commented out to preserve filters when activity changes
      // this.activitySource.reset();
    }
  }
}
