<span *ngIf="this.lock; else simpleIcon">
  <nb-icon
    pack="keyrack"
    [icon]="icon"
    [nbPopover]="popoverTemplate"
    [nbPopoverContext]="this.lock"
    nbPopoverTrigger="hover"
    nbPopoverPlacement="bottom"></nb-icon>
</span>

<ng-template #simpleIcon>
  <nb-icon pack="keyrack" [icon]="icon"></nb-icon>
</ng-template>

<ng-template #popoverTemplate let-key>
  <div *ngIf="!isAdmin">
    <span class="subtitle">Peg Name: {{ key.LockDetails.DisplayName }}</span>
    <br />
    <span *ngIf="key.LockDetails.LastUnlockedBy">
      <span
        >Last Unlocked By:
        {{
          key.LockDetails.LastUnlockedBy ? key.LockDetails.LastUnlockedBy.UserId : 'Unkonwn'
        }}</span
      >
      <br />
    </span>
    <span *ngIf="key.LockDetails.LastUnlockedBy">
      <span
        >Last Unlocked At:
        <lockvue-timezone-aware-date-time
          [value]="key.LockDetails.LastUnlockedAtUtc"
          unkownText="Unknown"></lockvue-timezone-aware-date-time
      ></span>
      <br />
    </span>
    <lockvue-hub-action-button
      [value]="{
        title: 'Details',
        url: 'locks',
        queryParams: { SerialNumber: lock.LockSerialNumber }
      }">
    </lockvue-hub-action-button>
    <lockvue-hub-action-button
      class="ml-1"
      [value]="{
        title: 'Activity',
        url: 'activity',
        queryParams: { SerialNumber: lock.LockSerialNumber }
      }">
    </lockvue-hub-action-button>
  </div>
  <div *ngIf="isAdmin">
    <span class="subtitle">Serial Number: {{ key.LockSerialNumber }}</span>
    <br />
    <lockvue-hub-action-button
      [value]="{
        title: 'Details',
        url: 'admin/locks',
        queryParams: { SerialNumber: lock.LockSerialNumber }
      }">
    </lockvue-hub-action-button>
    <lockvue-hub-action-button
      class="ml-1"
      [value]="{
        title: 'Activity',
        url: 'admin/activity',
        queryParams: { SerialNumber: lock.LockSerialNumber }
      }">
    </lockvue-hub-action-button>
  </div>
</ng-template>
