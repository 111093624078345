import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';

@Component({
  selector: 'lockvue-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss'],
})
export class RegisterSuccessComponent {
  showInstructions: boolean = true;

  constructor(private configService: LockvueConfigProviderService) {}

  get clientName(): string {
    return this.configService.getDWLClientName();
  }

  get supportEmail(): string {
    return this.configService.getSupportEmail();
  }
}
