import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HubsAdminService, RemoveLockFromHubModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-remove-lock-from-hub-button',
  templateUrl: './remove-lock-from-hub-button.component.html',
  styleUrls: ['./remove-lock-from-hub-button.component.scss'],
})
export class RemoveLockFromHubButtonComponent {
  @Input() value: any;

  @Output() lockRemoved: EventEmitter<void> = new EventEmitter();

  loading = false;

  get terminology() {
    return this.value.isKeyrack ? 'Peg' : 'Lock';
  }

  constructor(private hubsAdminService: HubsAdminService) {}

  removeLock() {
    this.loading = true;
    const lock: RemoveLockFromHubModel = {
      HubId: this.value.hubId,
      LockSerialNumber: this.value.serial,
    };
    this.hubsAdminService.hubsAdminRemoveLocksFromHub(lock).subscribe(x => {
      this.loading = false;
      this.lockRemoved.emit();
    });
  }
}
