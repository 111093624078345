<div>
  <h1 class="text-success">Just one more step...</h1>
  <p>
    You should receive an email from
    <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> containing a link to activate your
    {{ clientName }} account.
  </p>
  <p>
    You must activate your account before you can start using the {{ clientName }} mobile apps and
    web portal.
  </p>
  <hr />
  <div *ngIf="showInstructions">
    <p>
      <small
        ><i>
          NOTE: Sometimes the email can end up in "Others", "Promotions", "Social", or occasionally
          Spam folders. Please make sure you check these folders.
        </i></small
      >
    </p>
    <p>
      <small
        ><i>
          If you are behind a corporate network, you may need to ask your system administrator to
          whitelist the following email address:
          <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
        </i></small
      >
    </p>
  </div>
</div>
