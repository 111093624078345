/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type AggregationType = 'Hourly' | 'Daily' | 'Monthly';

export const AggregationType = {
    Hourly: 'Hourly' as AggregationType,
    Daily: 'Daily' as AggregationType,
    Monthly: 'Monthly' as AggregationType
};