<button
  nbButton
  outline
  status="primary"
  size="small"
  class="float-right"
  [disabled]="value.Locks.length === 0"
  (click)="openDetailWindow()">
  View {{ terminology }}
</button>
