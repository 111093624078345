import { Pipe, PipeTransform } from '@angular/core';
import { LockDetailsModel } from '../lockvue-ng-sdk';
import { LockvueComparatorsService } from '../services/lockvue-comparators.service';

@Pipe({
  name: 'sortLock',
})
export class LockSortPipe implements PipeTransform {
  constructor(private comparatorService: LockvueComparatorsService) {}

  transform(value: LockDetailsModel[], ...args: unknown[]): unknown {
    value.sort((a, b) => this.comparatorService.lockDetailsComparator(1, a, b));
    return value;
  }
}
