import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NbAuthOAuth2JWTToken,
  NbAuthResult,
  NbAuthStrategyClass,
  NbOAuth2AuthStrategy,
  NbOAuth2AuthStrategyOptions,
  NbOAuth2ResponseType,
  auth2StrategyOptions,
} from '@nebular/auth';
import { NB_WINDOW } from '@nebular/theme';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

export interface FusionAuthToken {
  user: UserDataResult;
  idToken: string;
  accessToken: string;
}

export class FusionAuthJWTToken extends NbAuthOAuth2JWTToken {
  static NAME: string = 'nb:auth:fusion-auth:token';

  protected readonly token: FusionAuthToken;

  getValue(): string {
    return this.token.accessToken;
  }
}

@Injectable()
export class FusionAuthStrategy extends NbOAuth2AuthStrategy {
  static setup(
    options: NbOAuth2AuthStrategyOptions,
  ): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [FusionAuthStrategy, options];
  }

  protected redirectResultHandlers: { [key: string]: Function } = {
    [NbOAuth2ResponseType.CODE]: () => {
      return this.oidcService.checkAuth().pipe(
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return this.oidcService.userData$;
          }
          return throwError('Authentication error');
        }),
        switchMap(data => {
          return combineLatest([
            of(data),
            this.oidcService.getIdToken(),
            this.oidcService.getAccessToken(),
          ]);
        }),
        map(([user, idToken, accessToken]) => {
          return {
            user,
            idToken,
            accessToken,
          } as FusionAuthToken;
        }),
        map(res => {
          return new NbAuthResult(
            true,
            {},
            this.getOption('redirect.success'),
            [],
            this.getOption('defaultMessages'),
            this.createToken(res, this.getOption(`${module}.requireValidToken`)),
          );
        }),
        catchError(e => {
          console.error('Caught authentication error', e);
          return of(
            new NbAuthResult(
              false,
              this.route.snapshot.queryParams,
              this.getOption('redirect.failure'),
              this.getOption('defaultErrors'),
              [],
            ),
          );
        }),
      );
    },
  };

  protected defaultOptions: NbOAuth2AuthStrategyOptions = auth2StrategyOptions;

  constructor(
    private oidcService: OidcSecurityService,
    protected http: HttpClient,
    protected route: ActivatedRoute,
    @Inject(NB_WINDOW) protected window: any,
  ) {
    super(http, route, window);
  }

  authenticate(data?: any): Observable<NbAuthResult> {
    return this.isRedirectResult().pipe(
      switchMap((result: boolean) => {
        // console.log('result', result);
        if (!result) {
          this.oidcService.authorize();
          return of(new NbAuthResult(true));
        }
        return this.getAuthorizationResult();
      }),
    );
  }
}
