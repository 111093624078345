import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NbTagComponent } from '@nebular/theme';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

type ResourceAutoSelectType = {
  DisplayName: string;
  Id: string;
  UID?: string;
};
@Component({
  selector: 'lockvue-resource-filter',
  templateUrl: './resource-filter.component.html',
  styleUrls: ['./resource-filter.component.scss'],
})
export class ResourceFilterComponent {
  constructor() {}

  private _myResources: ResourceAutoSelectType[] = [];
  private _selectedResources: Set<ResourceAutoSelectType> = new Set<ResourceAutoSelectType>();
  private selectedResources$: Subject<Set<ResourceAutoSelectType>> = new Subject();

  // To convert above subject to obvservable and limit the max tag available in UI
  selectedResourcesToShow$ = this.selectedResources$.pipe(
    map(v => {
      const resourceArray = Array.from(v);
      const arrayToShow = resourceArray
        .slice(0, this.maxTagCount)
        .map(v => ({ ...v, removable: true }));
      if (v.size > this.maxTagCount)
        arrayToShow.push({
          DisplayName: `and ${v.size - this.maxTagCount} more`,
          UID: '',
          Id: '',
          removable: false,
        });
      return arrayToShow;
    }),
  );

  filteredOptions$: Observable<ResourceAutoSelectType[]>;

  // Disable Input selection after max count has reached
  // Used in sharing popup when sharing with teams (maxSelectionCount=1)
  disableInput$: Observable<boolean> = this.selectedResources$.pipe(
    map(selectedResources => selectedResources.size >= this.maxSelectionCount),
  );

  @Input() set resources(_myResources: ResourceAutoSelectType[]) {
    this._myResources = _myResources;
    this.filteredOptions$ = of(_myResources);
  }

  get resources() {
    return this._myResources;
  }

  /** To limit the number of tags displayed in UI */
  @Input() maxTagCount: number = 1;

  @Input() placeholder: string = '';
  @Input() maxSelectionCount: number = Infinity;

  @Output() resourceFilterChange = new EventEmitter<string[]>();

  @ViewChild('tagInput') tagInput;

  private filter(string: string) {
    const filterString = string.toLowerCase();
    return this.resources.filter(
      locks =>
        locks.DisplayName.toLowerCase().includes(filterString) ||
        locks.UID?.toLowerCase().includes(filterString),
    );
  }

  private getFilteredOptions(value: string) {
    return of(value).pipe(map(filter => this.filter(filter)));
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.tagInput.nativeElement.value);
  }

  onTagAdd($event: string) {
    const lock = this.resources.find(l => l.Id === $event);

    this._selectedResources.add({
      DisplayName: lock.DisplayName,
      UID: lock.UID,
      Id: lock.Id,
    });

    this.resources = this.resources.filter(r => r.Id !== lock.Id);
    this.tagInput.nativeElement.value = '';
    this.selectedResources$.next(this._selectedResources);
    this.notifyChanges();
  }

  removeSelectedResource(tagToRemove: NbTagComponent) {
    this._selectedResources.forEach(v =>
      v.DisplayName === tagToRemove.text && v.Id === tagToRemove.role['Id']
        ? this._selectedResources.delete(v)
        : v,
    );
    this.selectedResources$.next(this._selectedResources);
    this.resources.push({
      DisplayName: tagToRemove.text,
      UID: tagToRemove.role['UID'],
      Id: tagToRemove.role['Id'],
    });
    this.notifyChanges();
  }

  private notifyChanges() {
    const selectedResourceIds = Array.from(this._selectedResources).map(v => v.Id);
    this.resourceFilterChange.emit(selectedResourceIds);
  }
}
