import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateFilterService {
  constructor() {}

  public getFilters(nullStr?: string): string[] {
    return [
      'Today',
      'In a week',
      'In a month',
      'In 6 months',
      nullStr ? nullStr : 'Never (Indefinite)',
    ];
  }

  public process(filter: string, date: any, past?: boolean, nullStr?: string): boolean {
    if (!filter) {
      return true;
    }

    if (!date) {
      if (filter === (nullStr ? nullStr : 'Never (Indefinite)')) {
        return true;
      }
      return false;
    }

    const inputDate = moment(date + 'Z');
    const now = moment().utc();

    if (past) {
      switch (filter) {
        case 'Today':
          return inputDate.isAfter(now.startOf('day')) && inputDate.isBefore(now.endOf('day'));
        case 'In a week':
          return inputDate.isAfter(now.add(-7, 'days'));
        case 'In a month':
          return inputDate.isAfter(now.add(-1, 'months'));
        case 'In 6 months':
          return inputDate.isAfter(now.add(-6, 'months'));
      }
    } else {
      switch (filter) {
        case 'Today':
          return inputDate.isAfter(now.startOf('day')) && inputDate.isBefore(now.endOf('day'));
        case 'In a week':
          return inputDate.isBefore(now.add(7, 'days'));
        case 'In a month':
          return inputDate.isBefore(now.add(1, 'months'));
        case 'In 6 months':
          return inputDate.isBefore(now.add(6, 'months'));
      }
    }
    return false;
  }
}
