import { Injectable } from '@angular/core';
import { LockActivationModel, LocksService } from '../lockvue-ng-sdk';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LockActivationService {
  constructor(private locksService: LocksService) {}

  activate(lockActivationModel: LockActivationModel): Observable<string> {
    return this.locksService.locksActivate(lockActivationModel);
  }
}
