/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type PermissionType = 'Admin' | 'View' | 'OneTime' | 'Maintenance' | 'Restricted';

export const PermissionType = {
    Admin: 'Admin' as PermissionType,
    View: 'View' as PermissionType,
    OneTime: 'OneTime' as PermissionType,
    Maintenance: 'Maintenance' as PermissionType,
    Restricted: 'Restricted' as PermissionType
};