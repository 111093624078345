import { Component, OnInit, Input } from '@angular/core';
import { LockActivityModel, Platform } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss'],
})
export class DeviceInfoComponent {
  @Input() value: Platform;
  @Input() rowData: LockActivityModel;

  @Input() showIcon: boolean = true;
  @Input() showText: boolean = false;

  constructor() {}
}
