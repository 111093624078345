import { Component, Input, OnInit } from '@angular/core';
import { LockActivityModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-transactional-telemetry',
  templateUrl: './transactional-telemetry.component.html',
  styleUrls: ['./transactional-telemetry.component.scss'],
})
export class TransactionalTelemetryComponent {
  @Input() transactions: LockActivityModel[];

  constructor() {}
}
