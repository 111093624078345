import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { NbWindowRef } from '@nebular/theme';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NotificationMessagesService } from '../../services/notification-messages.service';

interface ChangePasswordModel {
  Email?: string;
  OldPassword?: string;
  NewPassword?: string;
}

@Component({
  selector: 'lockvue-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationMessagesService,
    private windowRef: NbWindowRef,
    private auth: NbAuthService,
  ) {}

  @Input() private email: string;

  @Output() changed;

  private destroy$ = new Subject();

  newPassword: ChangePasswordModel = {};
  confirmPassword: string;

  ngOnInit(): void {
    this.newPassword.Email = this.email;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changePassword() {
    this.auth
      .getToken()
      .pipe(
        switchMap(token =>
          this.http.post(`${environment.authServerUrl}/Account/change-password`, this.newPassword, {
            headers: {
              Authorization: `Bearer ${token.getValue()}`,
            },
          }),
        ),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        x => {
          this.notificationService.showSuccess(
            'Success',
            'Your password was changed successfully!',
          );
          this.windowRef.close();
        },
        error => {
          this.notificationService.showError('Error', error.Message);
          this.windowRef.close();
        },
      );
  }
}
