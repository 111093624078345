<div class="container" *ngIf="isKeyrack; else onlyList">
  <nb-tabset fullWidth>
    <nb-tab class="nb-tab-content" tabTitle="Peg List">
      <nb-card>
        <nb-card-body>
          <lockvue-hub-lock-list-view
            [lockSource]="lockSource"
            [hubId]="hub.HubId"
            [isKeyrack]="isKeyrack"
            [isAdmin]="isAdmin"></lockvue-hub-lock-list-view>
        </nb-card-body>
      </nb-card>
    </nb-tab>
    <nb-tab [lazyLoad]="true" class="nb-tab-content" tabTitle="Peg View">
      <lockvue-hub-lock-key-view
        [locks]="hub.Locks"
        [rows]="hub.TotalRows"
        [isAdmin]="isAdmin"
        [cols]="hub.TotalColumns"
        [lastHeartbeat]="hub.LastHeartbeatUtc"></lockvue-hub-lock-key-view>
    </nb-tab>
  </nb-tabset>
</div>
<ng-template #onlyList>
  <div class="container">
    <nb-card>
      <nb-card-body>
        <lockvue-hub-lock-list-view
          [lockSource]="lockSource"
          [hubId]="hub.HubId"
          [isKeyrack]="isKeyrack"
          [isAdmin]="isAdmin"></lockvue-hub-lock-list-view>
      </nb-card-body>
    </nb-card>
  </div>
</ng-template>
