import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 * Collection of commonly used data comparators.
 * These are mainly used for sorting in smart-table components.
 */
export class LockvueComparatorsService {
  // for sorting strings with numbers use this
  private collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  constructor() {}

  /**
   * Comparator function to sort locks by battery percentage.
   * Pushes unknown values towards the end regardless of the direction.
   *
   * Parameters are string representations of battery percent values.
   */
  batteryComparator = (direction: any, a: any, b: any) => {
    // Parse % strings into integers
    const newA = parseInt(a, 10);
    const newB = parseInt(b, 10);

    // Always push unknown values towards the end
    if (isNaN(newA)) {
      return 1;
    }
    if (isNaN(newB)) {
      return -1;
    }

    // Compare known values
    if (newA < newB) {
      return -1 * direction;
    }
    if (newA > newB) {
      return direction;
    }
    return 0;
  };

  /**
   * Compares LockDetails objects using its DisplayName properties.
   * The comparison is performed as case-insensitive, and the unknowns (nulls)
   * are pushed towards the end regardless of the direction passed.
   *
   * The parameters are expected to be LockDetailsModel objects.
   */
  lockDetailsComparator = (direction: any, a: any, b: any) => {
    return this.stringComparator(direction, a.DisplayName, b.DisplayName);
  };

  usersComparator = (direction: any, a: any, b: any) => {
    return this.stringComparator(
      direction,
      (a.DisplayName ?? '') + '' + a.UserId,
      (b.DisplayName ?? '') + '' + b.UserId,
    );
  };

  /**
   * Performs case-insensitive comparison of given two strings.
   * The unknowns (such as nulls) are pushed towards the end regardless of the
   * direction passed.
   *
   * The passed parameters are expected to be strings.
   */
  stringComparator = (direction: any, a: any, b: any) => {
    // Always push unknown values towards the end
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }

    // Compare lower-case values
    if (this.collator.compare(a.toLowerCase(), b.toLowerCase()) < 0) {
      return -1 * direction;
    }
    if (this.collator.compare(a.toLowerCase(), b.toLowerCase()) > 0) {
      return direction;
    }
    return 0;
  };
  /**
   * Performs comparison based on number of locks available in a group / number of members available in teams.
   * The unknowns (such as groups/teams with 0 locks/members) are pushed towards the end regardless of the
   * direction passed.
   *
   * The passed parameters are expected to be lengths of respective arrays.
   */
  lengthComparator = (direction: number, a: number, b: number) => {
    if (a === 0) {
      return 1;
    }

    if (b === 0) {
      return -1;
    }

    // sorting in ascending / descending order depending on the length
    return (a - b) * direction;
  };

  groupNameComparator = (direction: number, a: any, b: any) => {
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    return this.stringComparator(direction, a.Name, b.Name);
  };
}
