<nb-card class="lv-confirmation-container">
  <nb-card-header>
    <div>Are you sure?</div>
  </nb-card-header>
  <nb-card-body>
    <p>
      Are you sure you want to remove
      <b>{{ group.Name ? group.Name : 'the selected lock group' }}</b
      >?
    </p>
    <br />
    <div *ngIf="!permissions || permissions.length === 0">
      <p>There are no permissions associated with this lock group.</p>
    </div>
    <div *ngIf="permissions && permissions.length > 0">
      <p *ngIf="permissions.length === 1">There is a permission associated with this lock group.</p>
      <p *ngIf="permissions.length > 1">
        There are {{ permissions.length }} permissions associated with this lock group.
      </p>
      <p><b>Deleting this group will also delete the associated permissions.</b></p>
      <!-- <p>
        <input
          id="inputRemoveAssociatedPermissions"
          type="checkbox"
          [(ngModel)]="revokePermissions" />
        &nbsp;
        <label for="inputRemoveAssociatedPermissions"> Revoke associated lock permissions. </label>
      </p> -->
      <p>
        <small>NOTE: This action cannot be undone.</small>
      </p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="wizard-nav-buttons">
      <button nbButton status="danger" (click)="submit()">Confirm</button>
      <button nbButton outline (click)="cancel()">Cancel</button>
    </div>
  </nb-card-footer>
</nb-card>
