import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-lock-billed',
  templateUrl: './lock-billed.component.html',
  styleUrls: ['./lock-billed.component.scss'],
})
export class LockBilledComponent implements OnInit {
  @Input() value: any;
  $disabled: Observable<boolean>;

  @Output() billedChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(private accessChecker: NbAccessChecker) {}

  ngOnInit(): void {
    this.$disabled = this.accessChecker.isGranted('modify', 'admin');
  }

  getBilled(): boolean {
    // value is for NotBilled
    return !this.value;
  }

  onBilledChanged($event) {
    this.billedChanged.emit($event);
  }
}
