/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type EventType = 'Locks' | 'LockGroups' | 'Permissions' | 'Bookings' | 'Teams' | 'Sites' | 'Assets' | 'Hubs';

export const EventType = {
    Locks: 'Locks' as EventType,
    LockGroups: 'LockGroups' as EventType,
    Permissions: 'Permissions' as EventType,
    Bookings: 'Bookings' as EventType,
    Teams: 'Teams' as EventType,
    Sites: 'Sites' as EventType,
    Assets: 'Assets' as EventType,
    Hubs: 'Hubs' as EventType
};