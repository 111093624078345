import { Component, OnInit, Input } from '@angular/core';
import { LockActivityUIModel } from '../../models/LockActivityUIModel';
import { TrendsUtilsService } from '../../services/trends-utils.service';

@Component({
  selector: 'lockvue-activity-trends-chart',
  templateUrl: './activity-trends-chart.component.html',
  styleUrls: ['./activity-trends-chart.component.scss'],
})
export class ActivityTrendsChartComponent {
  private _activity: LockActivityUIModel[];
  private _frequency: string = 'day';

  @Input()
  get activity(): LockActivityUIModel[] {
    return this._activity;
  }

  set activity(value: LockActivityUIModel[]) {
    this._activity = value;
    this.refreshChart();
  }

  @Input()
  get frequency(): string {
    return this._frequency;
  }

  set frequency(value: string) {
    this._frequency = value;
    this.refreshChart();
  }

  settings: any;

  constructor(private trendsUtilsService: TrendsUtilsService) {}

  refreshChart() {
    const dpAll = this.trendsUtilsService.getDatapoints(
      this._activity,
      'DateTimeUtc',
      this.frequency,
      null,
    );
    const dpUnlocks = this.trendsUtilsService.getDatapoints(
      this._activity,
      'DateTimeUtc',
      this.frequency,
      function (activity) {
        return activity.EventAction === 'Unlocked' || activity.EventAction === 'Shackle_Removed';
      },
    );
    const dpPermissions = this.trendsUtilsService.getDatapoints(
      this._activity,
      'DateTimeUtc',
      this.frequency,
      function (activity) {
        return (
          activity.EventAction === 'Granted' ||
          activity.EventAction === 'Revoked' ||
          activity.EventAction === 'Ownership_Transferred'
        );
      },
    );
    const datasets = this.trendsUtilsService.getDatasets(
      ['Unlocks', 'Permissions', 'All'],
      [dpUnlocks, dpPermissions, dpAll],
    );
    this.settings = this.trendsUtilsService.getDefaultChart(datasets, this.frequency);
  }
}
