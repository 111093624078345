import { Component, Input } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { GroupWithLockDetailsModel } from '../../lockvue-ng-sdk';
import { GroupLocksComponent } from '../group-locks/group-locks.component';
import { GroupActivityModel } from '../models/GroupActivityModel';

@Component({
  selector: 'lockvue-group-name',
  templateUrl: './group-name.component.html',
  styleUrls: ['./group-name.component.scss'],
})
export class GroupNameComponent {
  constructor(private windowService: NbWindowService) {}

  @Input() value: GroupWithLockDetailsModel;

  @Input() rowData: GroupActivityModel;

  showLocks() {
    this.windowService.open(GroupLocksComponent, {
      title: `Locks in ${this.value.Name}`,
      context: { locks: this.value.Locks },
    });
  }
}
