import { Component, OnInit, Input, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';
import { LockShareUIModel } from '../../models/LockShareUIModel';
import { LockShareType } from '../../models/LockShareType';
import {
  TeamsService,
  TeamDetailsModel,
  CodeDetailsModel,
  CodesService,
  LocksService,
  LockShareModel,
  ProfileService,
  UserModel,
  // IntegrationsService,
  LockGroupsService,
  PermissionType,
} from '../../lockvue-ng-sdk';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { LockvueConfigProviderService } from '../../services/lockvue-config-provider.service';
import { NbDialogService, NbStepperComponent } from '@nebular/theme';
import { PermissionHelpComponent } from '../permission-help/permission-help.component';
import { UmamiService } from '../../services/umami.service';

@Component({
  selector: 'lockvue-locks-share',
  templateUrl: './locks-share.component.html',
  styleUrls: ['./locks-share.component.scss'],
})
export class LocksShareComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() locks: LockDetailsUIModel[];
  @Input() groupId?: string;
  lockShareDetails: LockShareUIModel;
  LockShareType = LockShareType; // so that it can be used from HTML template
  permissionTypes = PermissionType;
  myTeams: TeamDetailsModel[] = [];
  activeCodes: CodeDetailsModel[] = [];
  lockShareSuccessCount: number = 0;
  lockShareFailureCount: number = 0;
  userProfile: UserModel;
  integrationsAllowed: boolean;
  shouldSendEmail: boolean = true;

  // Variables for storing the visibility status of date-time pickers
  startDateTimeVisible: boolean = false;
  endDateTimeVisible: boolean = false;

  @ViewChild('stepper', { static: true }) stepperComponent: NbStepperComponent;

  constructor(
    private teamsService: TeamsService,
    private codesService: CodesService,
    private locksService: LocksService,
    private lockGroupService: LockGroupsService,
    private profileService: ProfileService,
    private notificationsService: NotificationMessagesService,
    // private integrationsService: IntegrationsService,
    configService: LockvueConfigProviderService,
    private dialogService: NbDialogService,
    private umami: UmamiService,
  ) {
    this.integrationsAllowed = configService.integrationsAllowed();
  }

  ngOnInit() {
    this.initLockShareDetails();
    this.teamsService
      .teamsOwn()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(teams => {
        this.myTeams = teams.map(t => ({ DisplayName: t.Name, Id: t.Id }));
      });
    this.codesService
      .codesActive()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(codes => (this.activeCodes = codes));
    this.profileService
      .profileMy()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(profile => {
        this.userProfile = profile;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Initializes lock-share-details with default values
  private initLockShareDetails() {
    this.lockShareDetails = {
      PermissionType: PermissionType.Restricted,
      LockShareType: LockShareType.Individual,
      OneTimeEnabled: false,
      OneTimeWindowMins: 10,
      TimeRestrictionEnabled: true,
    } as LockShareUIModel;
  }

  onStartDateTimeChange() {
    this.startDateTimeVisible = false;
  }

  onEndDateTimeChange() {
    this.endDateTimeVisible = false;
  }

  showErrorSharing(l: LockDetailsUIModel, error: any) {
    this.notificationsService.showError(
      'Error while sharing a lock',
      'We encountered an error while sharing: ' + l.DisplayName,
      error,
    );
  }

  showErrorSharingGroup(g: string, error: any) {
    this.notificationsService.showError(
      'Error while sharing lock group',
      'We encountered an error while sharing lock group.',
      error,
    );
  }

  shareLocks() {
    if (this.groupId && this.groupId.length > 0) {
      this.shareLocksWithGroup();
    } else {
      this.shareLocksIndividually();
    }
  }

  shareLocksWithGroup() {
    // reset counters
    this.lockShareSuccessCount = 0;
    this.lockShareFailureCount = 0;
    const userIds = this.lockShareDetails.Recipient.trim().split(',');

    if (userIds && userIds.length > 1) {
      this.notificationsService.showError(
        'Too many recepients',
        `We can only share a lock group to one person individually.
        Please create a team if you want to share to multiple people.`,
      );
      return;
    }

    userIds.forEach(userId => {
      const email = userId.trim();
      switch (this.lockShareDetails.LockShareType) {
        case LockShareType.Individual:
          if (this.validateEmail(email)) {
            this.lockGroupService
              .lockGroupsShare({
                GroupId: this.groupId,
                UserId: email,
                PermissionType: this.lockShareDetails.PermissionType,
                StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                  ? this.lockShareDetails.TimeRestrictionStart
                  : null,
                EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                  ? this.lockShareDetails.TimeRestrictionEnd
                  : null,
                OneTimeAccessWindowMins:
                  this.lockShareDetails.PermissionType === 'OneTime'
                    ? this.lockShareDetails.OneTimeWindowMins
                    : null,
                ShouldSendEmail: this.shouldSendEmail,
              })
              .subscribe(
                x => {
                  this.lockShareSuccessCount += this.locks.length;
                  this.umami.track('group-shared-individual', {
                    permissionType: this.lockShareDetails.PermissionType,
                  });
                },
                error => {
                  this.lockShareFailureCount += this.locks.length;
                  this.showErrorSharingGroup(this.groupId, error);
                },
              );
          } else {
            this.notificationsService.showWarning(
              'Invalid email',
              `Email is not a valid email - ${userId}`,
            );
          }
          break;

        case LockShareType.UniqueCode:
          this.lockGroupService
            .lockGroupsShareWithCode({
              GroupId: this.groupId,
              UserId: email,
              PermissionType: this.lockShareDetails.PermissionType,
              StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionStart
                : null,
              EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionEnd
                : null,
              OneTimeAccessWindowMins:
                this.lockShareDetails.PermissionType === 'OneTime'
                  ? this.lockShareDetails.OneTimeWindowMins
                  : null,
              ShouldSendEmail: this.shouldSendEmail,
            })
            .subscribe(
              x => {
                this.lockShareSuccessCount += this.locks.length;
                this.umami.track('group-shared-code', {
                  permissionType: this.lockShareDetails.PermissionType,
                });
              },
              error => {
                this.lockShareFailureCount += this.locks.length;
                this.showErrorSharingGroup(this.groupId, error);
              },
            );
          break;

        case LockShareType.Team:
          this.lockGroupService
            .lockGroupsShareWithTeam({
              GroupId: this.groupId,
              TeamId: email,
              PermissionType: this.lockShareDetails.PermissionType,
              StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionStart
                : null,
              EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionEnd
                : null,
              OneTimeAccessWindowMins:
                this.lockShareDetails.PermissionType === 'OneTime'
                  ? this.lockShareDetails.OneTimeWindowMins
                  : null,
              ShouldSendEmail: this.shouldSendEmail,
            })
            .subscribe(
              x => {
                this.lockShareSuccessCount += this.locks.length;
                this.umami.track('group-shared-team', {
                  permissionType: this.lockShareDetails.PermissionType,
                });
              },
              error => {
                this.lockShareFailureCount += this.locks.length;
                this.showErrorSharingGroup(this.groupId, error);
              },
            );
          break;

        // IMPORTANT - NOT USED ANYMORE - WHEN INDARA INTEGRATION HAPPENS THIS WILL BE REMOVED.
        // case LockShareType.IntegrationPartner_HOMhero:
        //   // NOTE: Still shared individually
        //   this.locks.forEach(l => {
        //     this.integrationsService
        //       .integrationsShareWithHomstar({
        //         LockId: l.Id,
        //         StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
        //           ? this.lockShareDetails.TimeRestrictionStart
        //           : null,
        //         EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
        //           ? this.lockShareDetails.TimeRestrictionEnd
        //           : null,
        //       })
        //       .subscribe(
        //         x => ++this.lockShareSuccessCount,
        //         error => {
        //           ++this.lockShareFailureCount;
        //           this.showErrorSharing(l, error);
        //         },
        //       );
        //   });
        //   break;
      }
    });
  }

  shareLocksIndividually() {
    // reset counters
    this.lockShareSuccessCount = 0;
    this.lockShareFailureCount = 0;
    const userIds = this.lockShareDetails.Recipient.trim().split(',');

    if (userIds && userIds.length > 20) {
      this.notificationsService.showError(
        'Too many recepients',
        'We can only share locks with up to 20 users at a time.',
      );
      return;
    }

    switch (this.lockShareDetails.LockShareType) {
      case LockShareType.Individual:
        if (!Array.isArray(userIds)) {
          this.notificationsService.showError('Error', 'Invalid User or recepient');
          break;
        }
        if (!this.validateEmailArray(userIds)) {
          this.notificationsService.showError('Invalid Email', 'The email is invalid!');
          break;
        }
        this.locksService
          .locksBulkShare({
            LockIds: this.locks.map(l => l.Id),
            UserIds: userIds.map(ids => ids.trim()),
            PermissionType: this.lockShareDetails.PermissionType,
            StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
              ? this.lockShareDetails.TimeRestrictionStart
              : null,
            EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
              ? this.lockShareDetails.TimeRestrictionEnd
              : null,
            OneTimeAccessWindowMins:
              this.lockShareDetails.PermissionType === 'OneTime'
                ? this.lockShareDetails.OneTimeWindowMins
                : null,
            ShouldSendEmail: this.shouldSendEmail,
          })
          .subscribe(
            x => {
              this.lockShareSuccessCount = this.locks.length;
              this.umami.track('lock-shared-individual', {
                permissionType: this.lockShareDetails.PermissionType,
              });
            },
            error => {
              this.lockShareFailureCount = 0;
              this.notificationsService.showError('Lock Share!', `Something went wrong! ${error}`);
            },
          );
        break;

      case LockShareType.UniqueCode:
        this.locks.forEach(l => {
          this.locksService
            .locksShareWithCode({
              LockId: l.Id,
              UserId: userIds[0],
              PermissionType: this.lockShareDetails.PermissionType,
              StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionStart
                : null,
              EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionEnd
                : null,
              OneTimeAccessWindowMins:
                this.lockShareDetails.PermissionType === 'OneTime'
                  ? this.lockShareDetails.OneTimeWindowMins
                  : null,
              ShouldSendEmail: this.shouldSendEmail,
            })
            .subscribe(
              x => {
                ++this.lockShareSuccessCount;
                this.umami.track('lock-shared-code', {
                  permissionType: this.lockShareDetails.PermissionType,
                });
              },
              error => {
                ++this.lockShareFailureCount;
                this.showErrorSharing(l, error);
              },
            );
        });
        break;

      case LockShareType.Team:
        this.locks.forEach(l => {
          this.locksService
            .locksShareWithTeam({
              LockId: l.Id,
              TeamId: userIds[0],
              PermissionType: this.lockShareDetails.PermissionType,
              StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionStart
                : null,
              EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
                ? this.lockShareDetails.TimeRestrictionEnd
                : null,
              OneTimeAccessWindowMins:
                this.lockShareDetails.PermissionType === 'OneTime'
                  ? this.lockShareDetails.OneTimeWindowMins
                  : null,
              ShouldSendEmail: this.shouldSendEmail,
            })
            .subscribe(
              x => {
                ++this.lockShareSuccessCount;
                this.umami.track('lock-shared-teams', {
                  permissionType: this.lockShareDetails.PermissionType,
                });
              },
              error => {
                ++this.lockShareFailureCount;
                this.showErrorSharing(l, error);
              },
            );
        });
        break;
      // IMPORTANT - NOT USED ANYWHERE. WHEN INDARA INTEGRATION HAPPENS THIS WILL BE REMOVED
      // case LockShareType.IntegrationPartner_HOMhero:
      //   this.locks.forEach(l => {
      //     this.integrationsService
      //       .integrationsShareWithHomstar({
      //         LockId: l.Id,
      //         StartTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
      //           ? this.lockShareDetails.TimeRestrictionStart
      //           : null,
      //         EndTimeUTC: this.lockShareDetails.TimeRestrictionEnabled
      //           ? this.lockShareDetails.TimeRestrictionEnd
      //           : null,
      //       })
      //       .subscribe(
      //         x => ++this.lockShareSuccessCount,
      //         error => {
      //           ++this.lockShareFailureCount;
      //           this.showErrorSharing(l, error);
      //         },
      //       );
      //   });
      //   break;
    }
  }

  permissionHelp(highlightedColumn: string = null) {
    this.dialogService.open(PermissionHelpComponent, { context: { highlightedColumn } });
  }

  private validateEmailArray(emails: string[]) {
    for (const str of emails) {
      if (!this.validateEmail(str.trim())) {
        return false;
      }
    }
    return true;
  }

  private validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }

  teamsChanged($event) {
    this.lockShareDetails.Recipient = $event[0] ? $event[0] : undefined;
  }
}
