<!-- Expected events are Request, Unlocked, Peg Unlocked, Door Unlocked, Battery, Location, Locked, Shackle Removed -->
<div [ngSwitch]="event.EventAction">
  <div *ngSwitchCase="'Request'">
    <b>Requested For: </b> {{ requestValue }}<br />
    <b>Requested By: </b
    ><lockvue-user-details-item [value]="event.User"></lockvue-user-details-item>
  </div>
  <div *ngSwitchCase="'Location'">
    <b>Action: </b> {{ event.EventAction }}<br />
    <b>Location: </b><br />
    <div *ngIf="event.Value" class="map-div">
      <lockvue-embedded-map
        [latitude]="getLatitude()"
        [longitude]="getLongitude()"
        [showLargeMapText]="true"></lockvue-embedded-map>
    </div>
    <span *ngIf="!event.Value"> No Location Available! </span>
    <br />
  </div>
  <div *ngSwitchCase="'Battery'">
    <b>Battery: </b><lockvue-battery-level [value]="event.Value"></lockvue-battery-level>
  </div>
  <div *ngSwitchDefault>
    <!-- For Unlocked, Locked, Shackle Removed, Peg Unlocked and Door Unlocked -->
    <b>Action: </b> {{ event.EventAction.split('_').join(' ') }}<br />
    <b>{{ event.EventAction.split('_').join(' ') }} By: </b
    ><lockvue-user-details-item [value]="event.User"></lockvue-user-details-item>
  </div>
</div>
<b>Performed At: </b
><lockvue-timezone-aware-date-time [value]="event.DateTimeUtc"></lockvue-timezone-aware-date-time>
