/**
 * Defines the types of (recipient for) lock share
 */
export enum LockShareType {
  Individual = 'Individual',
  Team = 'Team',
  UniqueCode = 'Unique Code',
  IntegrationPartner_YBI = 'Partner_YesBookIt',
  IntegrationPartner_HOMhero = 'Partner_HOMhero',
}
