<nb-card class="container">
  <nb-card-header>
    <div>{{ title }}</div>
  </nb-card-header>
  <nb-card-body>
    <p>{{ value }}</p>
  </nb-card-body>
  <nb-card-footer>
    <div class="wizard-nav-buttons">
      <button nbButton status="danger" (click)="submit()">{{ confirmButtonText }}</button>
      <button nbButton outline (click)="cancel()">{{ cancelButtonText }}</button>
    </div>
  </nb-card-footer>
</nb-card>
