<div class="container">
  <div class="form-group">
    <label for="name" class="form-control-label">Lock Name</label>
    <input
      type="text"
      class="form-control"
      placeholder="Lock Name"
      id="name"
      nbInput
      fullWidth
      [(ngModel)]="value.DisplayName" />
  </div>
  <div class="form-group">
    <label for="name" class="form-control-label">Serial Number</label>
    <input
      type="text"
      class="form-control"
      placeholder="Lock Name"
      id="name"
      disabled
      nbInput
      fullWidth
      [(ngModel)]="value.UID" />
  </div>
  <div class="form-group">
    <label for="offlineCheckbox" class="form-control-label">Offline Mode</label>
    <br />
    <nb-checkbox [(ngModel)]="value.OfflineEnabled" id="offlineCheckbox">
      Enable Offline
    </nb-checkbox>
    <br />
    <small class="text-muted">
      <i>Offline Mode</i> allows you to access your lock without any network coverage. We recommend
      that you enable this mode only when necessary.
    </small>
  </div>
  <div class="wizard-nav-buttons">
    <button nbButton (click)="updateLock()" [disabled]="inProgress">Save Changes</button>
  </div>
</div>
