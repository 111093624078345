import { Component, Input, OnInit } from '@angular/core';
import { UserDetailsLimited } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-user-details-item',
  templateUrl: './user-details-item.component.html',
  styleUrls: ['./user-details-item.component.scss'],
})
export class UserDetailsItemComponent {
  @Input() value: UserDetailsLimited;

  /**
   * Whether to force a new line between the name and the email address.
   *
   * Examples:
   * true:
   * Nick Mooyman
   * (nick@astutesmartlocks.com)
   *
   * false:
   * Nick Mooyman (nick@astutesmartlocks.com)
   */
  @Input() forceNewLine: boolean = false;

  constructor() {}
}
