import { Component, Input } from '@angular/core';
import { LockDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-lock-info-short',
  templateUrl: './lock-info-short.component.html',
  styleUrls: ['./lock-info-short.component.scss'],
})

/**
 * Reusable component to briefly display lock information.
 * This component can be typically used as either the title
 * of a lock-info window or in a cell in a table.
 */
export class LockInfoShortComponent {
  @Input() value: LockDetailsModel;

  /**
   * true: display large information (such as in a header)
   * false: display smaller version (such as in a table cell)
   */
  @Input() large: boolean = false;

  @Input() iconColor?: string = 'black';

  constructor() {}
}
