<div class="table-container" [nbSpinner]="loading">
  <ng2-smart-table
    [settings]="tableSettings"
    [source]="activitySource"
    (rowHover)="onRowSelect($event)">
  </ng2-smart-table>
</div>
<div *ngIf="showSlideout">
  <ngx-slide-out [open]="false">
    <span *ngIf="!selectedActivity"> Please select a lock activity item to view details. </span>

    <div *ngIf="selectedActivity">
      <lockvue-activity-info [value]="selectedActivity"></lockvue-activity-info>
    </div>
  </ngx-slide-out>
</div>
