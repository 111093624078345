import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lockvue-hub-action-button',
  templateUrl: './hub-action-button.component.html',
  styleUrls: ['./hub-action-button.component.scss'],
})
export class HubActionButtonComponent {
  @Input() value: any;

  constructor(private router: Router) {}

  redirect() {
    this.router.navigate([this.value.url], { queryParams: this.value.queryParams });
  }
}
