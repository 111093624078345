<div class="container">
  <nb-card size="medium">
    <nb-card-body class="d-flex">
      <div class="p-2 sticky">
        <lockvue-lock-info-short
          [value]="transactions[0].LockDetails"
          [large]="true"></lockvue-lock-info-short>
        <div>
          <span><b>Device: </b></span>
          &nbsp;
          <lockvue-device-info
            *ngIf="transactions[0].Platform"
            [value]="transactions[0].Platform"
            [rowData]="transactions[0]"
            [showText]="true">
          </lockvue-device-info>
        </div>
        <br />
        <div><b>Transaction Id:</b> {{ transactions[0].TransactionId }}</div>
      </div>
      <div class="p-2">
        <h2>Events In Transaction</h2>
        <hr />
        <div class="pb-3">
          <div class="timeline">
            <div class="timeline-container" *ngFor="let event of transactions">
              <div class="content">
                <lockvue-transactional-telemetry-item
                  [event]="event"></lockvue-transactional-telemetry-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
