import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lockvue-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.css'],
})
export class DropdownButtonComponent implements OnInit, OnDestroy {
  constructor(private menuService: NbMenuService) {}

  @Input() menu: NbMenuItem[];
  @Input() title: string;
  @Input() icon: string;
  @Input() tag: string;

  protected destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.menuService
      .onItemClick()
      .pipe(
        takeUntil(this.destroy$),
        filter(value => value.tag === this.tag),
      )
      .subscribe(event => {
        if (event.item.data && typeof event.item.data.callback === 'function') {
          event.item.data.callback();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
