import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NbCalendarRange, NbDatepickerDirective, NbPopoverDirective } from '@nebular/theme';
import * as moment from 'moment';
import { NotificationMessagesService } from '../../services/notification-messages.service';

@Component({
  selector: 'lockvue-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
})
export class DateRangeSelectorComponent implements OnInit {
  _range: NbCalendarRange<Date>;
  differenceAllowed: number;

  options = [
    { value: 'Last 24h', label: 'Last 24h' },
    { value: 'Last 7D', label: 'Last 7D' },
    { value: 'Last 30D', label: 'Last 30D' },
    { value: 'Last 90D', label: 'Last 90D' },
    { value: 'Custom', label: 'Custom' },
  ];
  option: string = 'Last 24h';
  previousOption: string = 'Last 24h';
  buttonText: string = '';
  startDate: string;
  endDate: string;

  get range(): NbCalendarRange<Date> {
    return this._range;
  }

  @Input() isAdmin: boolean = false;

  @Output() rangeChange: EventEmitter<any>;

  @ViewChild(NbDatepickerDirective, { static: true }) dateRangePicker: NbDatepickerDirective<any>;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  constructor(private notificationService: NotificationMessagesService) {
    this.rangeChange = new EventEmitter();
  }

  ngOnInit() {
    this.updateText(this.option);
    this.differenceAllowed = this.isAdmin ? 3 : 12;
  }

  // Helper to update range and sent event notification
  notifyRangeChanged(start: Date, end: Date) {
    const value = {
      start: start,
      end: end,
    } as NbCalendarRange<Date>;
    this.rangeChanged(value);
  }

  updateText(value: string) {
    this.buttonText = value;
  }

  rangeChanged($event) {
    if ($event.start && $event.end) {
      if (moment($event.start).diff($event.end, 'months', false) < -this.differenceAllowed) {
        this.notificationService.showError(
          'Max Range!',
          `The difference between the date should be maximum ${this.differenceAllowed} months.`,
        );
        return;
      }
      if (moment($event.start).diff($event.end) > 0) {
        this.notificationService.showError(
          'Invalid Date!',
          'Start date can not be after end date!',
        );
        return;
      }
      this.rangeChange.emit($event);
    }
  }

  startDateChange($event) {
    this.startDate = moment($event).format('DD-MMM-YYYY HH:mm');
  }
  endDateChange($event) {
    this.endDate = moment($event).format('DD-MMM-YYYY HH:mm');
  }

  apply() {
    switch (this.option) {
      case 'Last 24h':
        this.notifyRangeChanged(moment().add(-1, 'days').utc().toDate(), moment().utc().toDate());
        this.updateText(this.option);
        this.previousOption = this.option;
        this.close();
        break;
      case 'Last 7D':
        this.notifyRangeChanged(moment().add(-1, 'weeks').utc().toDate(), moment().utc().toDate());
        this.updateText(this.option);
        this.previousOption = this.option;
        this.close();
        break;
      case 'Last 30D':
        this.notifyRangeChanged(moment().add(-1, 'month').utc().toDate(), moment().utc().toDate());
        this.updateText(this.option);
        this.previousOption = this.option;
        this.close();
        break;
      case 'Last 90D':
        this.notifyRangeChanged(moment().add(-90, 'days').utc().toDate(), moment().utc().toDate());
        this.updateText(this.option);
        this.previousOption = this.option;
        this.close();
        break;
      case 'Custom':
        if (this.startDate && this.endDate) {
          this.notifyRangeChanged(
            moment(this.startDate, 'DD-MMM-YYYY HH:mm').utc().toDate(),
            moment(this.endDate, 'DD-MMM-YYYY HH:mm').utc().toDate(),
          );
          this.updateText(this.option);
          this.previousOption = this.option;
          this.close();
        } else {
          this.notificationService.showWarning('Oops!', 'Please select a date and click apply.');
        }
        break;
    }
  }

  open() {
    this.popover.show();
  }
  close() {
    if (this.previousOption !== this.option) {
      this.option = this.previousOption;
    }
    this.popover.hide();
  }
}
