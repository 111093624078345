import { Component, OnInit } from '@angular/core';
import { NbTokenService } from '@nebular/auth';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LocalStorageKeys } from '../../../services/constants/LocalStorageKeys';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'lockvue-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private tokenService: NbTokenService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private oidcService: OidcSecurityService,
  ) {}

  ngOnInit() {
    this.tokenService.get().subscribe(token => {
      if (token.getOwnerStrategyName() === 'fusion-auth')
        this.oidcService.logoffAndRevokeTokens().subscribe();
      this.tokenService.clear().subscribe();
      this.localStorageService.removeItem(LocalStorageKeys.Alias);
      if (token.getOwnerStrategyName() === 'email') this.router.navigate(['/auth/login']);
    });
  }
}
