<div>
  <div class="form-group">
    <label for="name" class="form-control-label">Name</label>
    <input
      type="text"
      class="form-control"
      placeholder="Name"
      id="name"
      nbInput
      fullWidth
      [(ngModel)]="name" />
  </div>
  <div class="form-group">
    <label for="description" class="form-control-label">Description</label>
    <input
      type="text"
      class="form-control"
      placeholder="Description"
      id="description"
      nbInput
      fullWidth
      [(ngModel)]="description" />
  </div>
</div>
