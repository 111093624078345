<div class="activity-details">
  <lockvue-lock-info-short *ngIf="value.LockDetails" [value]="value.LockDetails" [large]="true">
  </lockvue-lock-info-short>
  <h2 *ngIf="!value.LockDetails">
    <i>Unknown Lock</i>
  </h2>
  <div>
    <span><b>Device: </b></span>
    &nbsp;
    <lockvue-device-info
      *ngIf="value.Platform"
      [value]="value.Platform"
      [rowData]="value"
      [showText]="true">
    </lockvue-device-info>
    <span *ngIf="!value.Platform">Unknown</span>
  </div>
  <br />
  <!-- <div *ngIf="value.LockDetails.LockStatus">
    <lockvue-lock-status
      [LastLockStatus]="value.LockDetails.LockStatus"
      [LastLockStatusUpdatedAt]="value.LockDetails.LockStatusUpdatedOnUtc">
    </lockvue-lock-status>
    <br />
  </div> -->
  <div>
    <span><b>User: </b></span>
    <lockvue-user-alias [value]="value"></lockvue-user-alias>
  </div>
  <br />
  <div *ngIf="value.Value && ['Ownership_Transferred', 'Modified'].includes(value.EventAction)">
    <div>
      <span><b>Description: </b>{{ value.Value }}</span>
    </div>
    <br />
  </div>
  <div *ngIf="value.Value && ['Granted', 'Revoked'].includes(value.EventAction)">
    <div>
      <span><b>Description: </b>{{ value.EventAction }}: {{ value.Value.split(' ')[0] }} </span>
    </div>
    <br />
  </div>
  <div *ngIf="value.Value && lockPositionEvents.includes(value.EventAction)">
    <div>
      <span
        ><b>Peg Position: </b>
        {{
          value.Value.split(',').length > 1
            ? 'List ' + value.Value.split(',')[0] + ' Position ' + value.Value.split(',')[1]
            : value.Value
        }}
      </span>
    </div>
    <br />
  </div>
  <div>
    <span><b>Location: </b></span>
    <div *ngIf="isMapAvailable()" class="map-div">
      <lockvue-embedded-map
        [latitude]="getLatitude()"
        [longitude]="getLongitude()"
        [showLargeMapText]="true">
      </lockvue-embedded-map>
    </div>
    <div *ngIf="!isMapAvailable()">
      <span class="italic">No location information available for this event.</span>
    </div>
  </div>
</div>
