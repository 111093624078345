/**
 * LockVue API
 * APIs to interact with LockVue Devices and Telemetry
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AdminApiKeyCreateModel } from '../model/adminApiKeyCreateModel';
import { AdminApiKeysGetModel } from '../model/adminApiKeysGetModel';
import { ApiKey } from '../model/apiKey';
import { ApiKeyRequest } from '../model/apiKeyRequest';
import { ApiRole } from '../model/apiRole';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {

    protected basePath = 'http://localhost:5192/api/v2';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create an Api key
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysAdd(body?: AdminApiKeyCreateModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiKeysAdd(body?: AdminApiKeyCreateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiKeysAdd(body?: AdminApiKeyCreateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiKeysAdd(body?: AdminApiKeyCreateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/admin/ApiKeys/Add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a role to be used for Api Keys
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysAddRoles(body?: Array<ApiRole>, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiKeysAddRoles(body?: Array<ApiRole>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiKeysAddRoles(body?: Array<ApiRole>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiKeysAddRoles(body?: Array<ApiRole>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/admin/ApiKeys/AddRoles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Api Keys
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysAll(body?: AdminApiKeysGetModel, observe?: 'body', reportProgress?: boolean): Observable<Array<ApiKey>>;
    public apiKeysAll(body?: AdminApiKeysGetModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiKey>>>;
    public apiKeysAll(body?: AdminApiKeysGetModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiKey>>>;
    public apiKeysAll(body?: AdminApiKeysGetModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ApiKey>>('post',`${this.basePath}/admin/ApiKeys/All`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a new Api Key
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysCreateApiKey(body?: ApiKeyRequest, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiKeysCreateApiKey(body?: ApiKeyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiKeysCreateApiKey(body?: ApiKeyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiKeysCreateApiKey(body?: ApiKeyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/ApiKeys/CreateApiKey`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an existing Api key
     * 
     * @param Id Id of Api Key to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysDelete(Id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiKeysDelete(Id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiKeysDelete(Id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiKeysDelete(Id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/admin/ApiKeys/Delete`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete an Api Key.
     * 
     * @param Id Id of Api key to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysDeleteApiKey(Id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiKeysDeleteApiKey(Id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiKeysDeleteApiKey(Id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiKeysDeleteApiKey(Id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/ApiKeys/DeleteApiKey`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a decrypted Api Key. CAUTION: This should not be done often to maintain security
     * 
     * @param Id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysGetActualApiKey(Id?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public apiKeysGetActualApiKey(Id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public apiKeysGetActualApiKey(Id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public apiKeysGetActualApiKey(Id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Id !== undefined && Id !== null) {
            queryParameters = queryParameters.set('id', <any>Id);
        }

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/ApiKeys/GetActualApiKey`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Api Keys
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysGetApiKeys(observe?: 'body', reportProgress?: boolean): Observable<Array<ApiKey>>;
    public apiKeysGetApiKeys(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiKey>>>;
    public apiKeysGetApiKeys(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiKey>>>;
    public apiKeysGetApiKeys(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiKey>>('get',`${this.basePath}/ApiKeys/GetApiKeys`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of Roles
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiKeysGetRoles(observe?: 'body', reportProgress?: boolean): Observable<Array<ApiRole>>;
    public apiKeysGetRoles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiRole>>>;
    public apiKeysGetRoles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiRole>>>;
    public apiKeysGetRoles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (ApiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["x-api-key"]) {
            headers = headers.set('x-api-key', this.configuration.apiKeys["x-api-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiRole>>('get',`${this.basePath}/ApiKeys/GetRoles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
