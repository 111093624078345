import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  private subject: Subject<any> = new Subject();

  constructor() {}

  emitWithMessage(message: any) {
    this.subject.next(message);
  }

  emit() {
    this.subject.next();
  }

  listen() {
    return this.subject.asObservable();
  }
}
