import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LockvueConfigProviderService } from '../../../services/lockvue-config-provider.service';
import { NotificationMessagesService } from '../../../services/notification-messages.service';

@Component({
  selector: 'lockvue-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  password: any;
  confirmPassword: any;
  code: string;

  // TODO
  submitted: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private configProvider: LockvueConfigProviderService,
    private notificationsService: NotificationMessagesService,
  ) {}

  ngOnInit() {
    try {
      // get nonce / authorization code required to reset password
      this.code = this.route.snapshot.queryParams['code'];
      this.code = this.code.replace(/\s/g, '+');
    } catch {
      this.notificationsService.showError(
        'Invalid Link',
        'Invalid code found in the password reset link. Please try again.',
      );
    }
  }

  submit() {
    const url = this.configProvider.getAuthServerUrl() + '/Account/reset-password';
    this.http
      .post(url, {
        Email: this.email,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        Code: this.code,
      })
      .subscribe(
        result => {
          this.notificationsService.showSuccess('Success', 'Your password was reset successfully.');
          // redirect to login page
          this.router.navigate(['/auth/login']);
        },
        error => {
          this.notificationsService.showError(
            'Error',
            'We encountered an error while attempting to reset your password.',
            error,
          );
        },
      );
  }
}
