import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lockvue-lock-group-manage-locks',
  templateUrl: './lock-group-manage-locks.component.html',
  styleUrls: ['./lock-group-manage-locks.component.scss'],
})
export class LockGroupManageLocksComponent {
  constructor() {}
}
