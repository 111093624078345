import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LockGroupsService } from '../../lockvue-ng-sdk';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NbStepperComponent } from '@nebular/theme';
import { LockDetailsUIModel } from '../../models/LockDetailsWithPermissionModel';

@Component({
  selector: 'lockvue-new-lock-group',
  templateUrl: './new-lock-group.component.html',
  styleUrls: ['./new-lock-group.component.scss'],
})
export class NewLockGroupComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  lockGroupName: string = '';
  lockGroupDescription: string = '';
  lockGroupId: string;
  inProgress: boolean;
  selectedLocks: Array<{ Name: string; Id: string; Removable: boolean }>;

  @ViewChild('stepper', { static: true }) stepperComponent: NbStepperComponent;

  constructor(
    private lockGroupsService: LockGroupsService,
    private notificationsService: NotificationMessagesService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createLockGroup() {
    this.inProgress = true;
    this.lockGroupsService
      .lockGroupsAdd({
        Name: this.lockGroupName ? this.lockGroupName.trim() : '',
        Description: this.lockGroupDescription ? this.lockGroupDescription.trim() : '',
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        result => {
          this.inProgress = false;
          this.lockGroupId = result;
          this.notificationsService.showSuccess('Success', 'Lock Group created successfully.');
        },
        error => {
          this.inProgress = false;
          this.notificationsService.showError(
            'Error',
            'Lock Group creation failed. Error: ' + (error ? error.message : 'Unknown'),
          );
          this.stepperComponent.reset();
        },
      );
  }

  addSelectedLock() {
    if (!this.selectedLocks || this.selectedLocks.length === 0) {
      this.notificationsService.showError('No locks selected', 'No Locks were added to the group!');
    }

    this.selectedLocks.forEach(l => {
      this.lockGroupsService
        .lockGroupsAddLockToGroup({
          GroupId: this.lockGroupId,
          LockId: l.Id,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          result => {
            this.inProgress = false;
            this.notificationsService.showSuccess(
              'Success',
              'Lock: ' + l.Name + ' was successfully added to the lock group.',
            );
          },
          error => {
            this.inProgress = false;
            this.notificationsService.showError(
              'Error',
              'Error while adding lock: ' +
                l.Name +
                ' to the lock group: ' +
                (error ? error.message : 'Unknown'),
            );
          },
        );
    });
  }

  selectedLocksChange($event: Set<{ Name: string; Id: string; Removable: boolean }>) {
    this.selectedLocks = [...$event].filter(v => v.Removable === true);
  }
}
