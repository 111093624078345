<div class="container">
  <div class="row justify-content-center">
    <div class="text-center mb-4"><div class="img-fluid"></div></div>
    <div class="clearfix">
      <p>Congrats! You have successfully verified your email address.</p>
      <p>
        To unlock your smart lock(s), please login to the {{ clientName }} app on your smartphone.
      </p>
      <p>
        To activate & manage your smart lock(s), please <a href="/auth/login">login</a> to the
        {{ clientName }} web portal using any browser on your PC or laptop.
      </p>
    </div>
  </div>
</div>
