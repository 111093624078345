import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * A very simple service to notify subscribers about a change in user profile.
 *
 * An exmple would be Settings component updating user display-name notifies about the
 * change and the header component listens to the event and updates the user display name.
 */
export class UserProfileUpdateService {
  private profileUpdatedSource = new Subject<void>();
  public onProfileUpdated = this.profileUpdatedSource.asObservable();

  constructor() {}

  public profileUpdated() {
    this.profileUpdatedSource.next();
  }
}
