<span
  *ngIf="lastUnlockedAtUtc"
  title="{{ lastUnlockedAtUtc.toString() + 'Z' | date: 'd-MMM-yyyy hh:mm a' }}">
  {{ lastUnlockedAtUtc.toString() + 'Z' | amTimeAgo }}
</span>
<span
  *ngIf="lastUnlockedAtUtc && lastUnlockedBy && lastUnlockedBy.UserId"
  class="d-none d-sm-inline">
  <i>by </i>
</span>
<span *ngIf="lastUnlockedBy && lastUnlockedBy.UserId" class="d-none d-sm-inline">
  <lockvue-user-details-item [value]="lastUnlockedBy"></lockvue-user-details-item>
  <i
    *ngIf="!lastUnlockedBy.UserId.includes('@')"
    class="ion-key ml-1"
    title="Unlocked using a Unique Code."></i>
</span>

<span
  *ngIf="!lastUnlockedAtUtc && (!lastUnlockedBy || !lastUnlockedBy.UserId)"
  class="d-none d-sm-table-cell">
  Unknown
</span>
