import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lockvue-new-item-button',
  templateUrl: './new-item-button.component.html',
  styleUrls: ['./new-item-button.component.scss'],
})
export class NewItemButtonComponent {
  @Input() text: string;
  @Input() tooltip: string;

  constructor() {}
}
