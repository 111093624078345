import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LockvueConfigProviderService } from '../../services/lockvue-config-provider.service';

@Component({
  selector: 'lockvue-permission-help',
  templateUrl: './permission-help.component.html',
  styleUrls: ['./permission-help.component.scss'],
})
export class PermissionHelpComponent {
  @Input() highlightedColumn: string = null;

  get clientName(): string {
    return this.configProvider.getDWLClientName();
  }

  constructor(
    private dialogRef: NbDialogRef<PermissionHelpComponent>,
    private configProvider: LockvueConfigProviderService,
  ) {}

  close() {
    this.dialogRef.close();
  }
}
