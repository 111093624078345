<div class="container">
  <nb-stepper orientation="horizontal" [disableStepNavigation]="true" #stepper>
    <nb-step label="Team">
      <div>
        <lockvue-basic-details [(name)]="teamName" [(description)]="teamDescription">
        </lockvue-basic-details>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton nbStepperNext (click)="createTeam()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Members">
      <h6>Add users to this team</h6>
      <div>
        <nb-tag-list [nbSpinner]="inProgress">
          <input
            placeholder="Enter email and press enter to add member"
            nbInput
            nbTagInput
            fullWidth
            (tagAdd)="addSelectedMember($event)"
            type="email" />
          <nb-tag
            [removable]="false"
            *ngFor="let member of currentTeamMembers"
            [text]="member"></nb-tag>
        </nb-tag-list>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton (click)="next()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Done">
      <h6>Success</h6>
      <div class="results">
        <p>Congratulations! You have successfully created your team.</p>
        <p>Teams allow you to share locks and groups of locks with multiple users together.</p>
        <p>
          To make any changes please send us a message via the
          <a href="https://lockvue.zendesk.com/hc/en-us/requests/new" target="_blank">Support</a>
          page.
        </p>
      </div>
    </nb-step>
  </nb-stepper>
</div>
