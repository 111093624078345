import { Component, Input } from '@angular/core';
import { LockActivityUIModel } from '../../models/LockActivityUIModel';
import { EventAction } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-activity-info',
  templateUrl: './activity-info.component.html',
  styleUrls: ['./activity-info.component.scss'],
})
export class ActivityInfoComponent {
  @Input() value: LockActivityUIModel;

  private locationEnabledEventActions: string[] = [];
  lockPositionEvents: string[] = [];

  constructor() {
    this.locationEnabledEventActions = [
      EventAction.Unlocked,
      EventAction.ShackleRemoved,
      EventAction.PegUnlocked,
      EventAction.DoorUnlocked,
      EventAction.Location,
    ];
    this.lockPositionEvents = [EventAction.PegReturned, EventAction.PegRemoved];
  }

  isMapAvailable(): boolean {
    const result =
      this.value.Value && this.locationEnabledEventActions.includes(this.value.EventAction);
    return result;
  }

  getLatitude() {
    if (this.value.Value && this.value.Value.includes(',')) {
      const result = this.value.Value.split(',')[0];
      return parseFloat(result);
    }
    return null;
  }

  getLongitude() {
    if (this.value.Value && this.value.Value.includes(',')) {
      const result = this.value.Value.split(',')[1];
      return parseFloat(result);
    }
    return null;
  }
}
