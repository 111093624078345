import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lockvue-lock-status',
  templateUrl: './lock-status.component.html',
  styleUrls: ['./lock-status.component.scss'],
})
export class LockStatusComponent {
  constructor() {}

  @Input() LastLockStatus: string;
  @Input() LastLockStatusUpdatedAt: string;
}
