import { Component, Input, OnInit } from '@angular/core';
import { UserModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-lock-owner',
  templateUrl: './lock-owner.component.html',
  styleUrls: ['./lock-owner.component.scss'],
})
export class LockOwnerComponent {
  constructor() {}

  @Input() value: UserModel;

  getText(): string {
    return this.value.UserId;
  }
}
