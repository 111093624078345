import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbTokenService, NbAuthJWTToken } from '@nebular/auth';

@Component({
  selector: 'lockvue-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss'],
})
export class AutoLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: NbTokenService,
  ) {}

  ngOnInit() {
    try {
      // get raw token value from the URL query params
      const tokenRaw = this.route.snapshot.queryParams['token'];

      // create and store nebular jwt token object
      const tokenObj = new NbAuthJWTToken(tokenRaw, 'email');
      this.tokenService.set(tokenObj);
    } catch {
      console.error('Could not auto login. Please login again.');
    }

    // redirect to dashboard
    this.router.navigate(['/dashboard']);
  }
}
