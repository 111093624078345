<div class="pl-1">
  <nb-tag-list (tagRemove)="removeSelectedResource($event)">
    <nb-tag
      *ngFor="let t of selectedResourcesToShow$ | async"
      size="tiny"
      [text]="t.DisplayName"
      [role]="{ Id: t.Id, UID: t.UID }"
      [removable]="t.removable"></nb-tag>
    <input
      #tagInput
      nbTagInput
      fullWidth
      type="text"
      [disabled]="disableInput$ | async"
      [placeholder]="(disableInput$ | async) ? '' : placeholder"
      [nbAutocomplete]="auto"
      (input)="onChange()" />
  </nb-tag-list>

  <nb-autocomplete #auto (selectedChange)="onTagAdd($event)">
    <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option.Id">
      <p>
        {{ option.DisplayName }} <span *ngIf="option.UID" class="small"> ({{ option.UID }})</span>
      </p>
    </nb-option>
  </nb-autocomplete>
</div>
