import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LockDetailsModel } from '../../lockvue-ng-sdk';
import { NotificationMessagesService } from '../../services/notification-messages.service';
import { SubjectService } from '../../services/subject.service';
import { TableSettingsProviderService } from '../../services/table-settings-provider.service';

@Component({
  selector: 'lockvue-hub-lock-list-view',
  templateUrl: './hub-lock-list-view.component.html',
  styleUrls: ['./hub-lock-list-view.component.scss'],
})
export class HubLockListViewComponent implements OnInit, OnDestroy {
  private _lockSource: LocalDataSource;
  selectedLock: LockDetailsModel;
  private _isKeyrack: boolean;
  private _isAdmin: boolean;

  /**
   * calling initTableSettings on setters to update the table views when one of these setter is changed.
   * calling on ngOnInit will not work because @Inputs will be undefined at that time.
   */
  @Input() set lockSource(lockSource: LocalDataSource) {
    this._lockSource = lockSource;
    this.initTableSettings();
  }
  get lockSource() {
    return this._lockSource;
  }

  @Input() set isKeyrack(value: boolean) {
    this._isKeyrack = value;
    this.initTableSettings();
  }
  get isKeyrack() {
    return this._isKeyrack;
  }

  @Input() set isAdmin(value: boolean) {
    this._isAdmin = value;
    this.initTableSettings();
  }
  get isAdmin() {
    return this._isAdmin;
  }

  @Input() hubId: string;

  settings: any;

  private destroy$ = new Subject();
  constructor(
    private tableSettingsProvider: TableSettingsProviderService,
    private notificationService: NotificationMessagesService,
    private subjectService: SubjectService,
  ) {}

  ngOnInit() {
    // for updating lock billed status
    this.subjectService
      .listen()
      .pipe(
        filter(v => v.for === 'hubsAdmin'),
        takeUntil(this.destroy$),
      )
      .subscribe(v => {
        if (v.value) this.lockRemoved();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  initTableSettings() {
    if (!this.isAdmin) {
      this.settings = this.tableSettingsProvider.getHubsPageTableSettings(this.isKeyrack);
    } else {
      this.settings = this.tableSettingsProvider.getAdminHubsPageTableSettings(
        this.isKeyrack,
        this.hubId,
      );
    }
  }

  onRowSelect($event) {
    this.selectedLock = $event.data.LockDetails ? $event.data.LockDetails : $event.data;
  }

  lockRemoved() {
    this.lockSource.remove(this.selectedLock);
    this.lockSource.refresh();
    this.notificationService.showSuccess('Success', 'Lock has been removed from the hub.');
  }
}
