import { Component, Input, OnInit } from '@angular/core';
import { HubDetailsModel } from '../../lockvue-ng-sdk';

@Component({
  selector: 'lockvue-hub-short-info',
  templateUrl: './hub-short-info.component.html',
  styleUrls: ['./hub-short-info.component.scss'],
})
export class HubShortInfoComponent {
  @Input() hub: HubDetailsModel;

  get terminology() {
    return this.hub.HubType.includes('KeyRack') ? 'Pegs' : 'Locks';
  }

  constructor() {}
}
