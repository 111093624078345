<div class="container">
  <nb-stepper orientation="horizontal" [disableStepNavigation]="true" #stepper>
    <nb-step label="Lock Group">
      <div>
        <lockvue-basic-details [(name)]="value.Name" [(description)]="value.Description">
        </lockvue-basic-details>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton nbStepperNext (click)="editLockGroup()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Locks">
      <h6>Add locks to this lock group</h6>
      <div [nbSpinner]="inProgress">
        <lockvue-lock-selector
          [selectedLocks]="value.Locks"
          (selectedLocksChange)="selectedLocksChange($event)">
        </lockvue-lock-selector>
      </div>
      <div class="mt-3">
        <!-- <nb-checkbox (checkedChange)="toggleCopyPermissionsCheckbox($event)">
          Automatically grant permissions
        </nb-checkbox>
        &nbsp;
        <nb-icon
          icon="ion-ios-information"
          nbTooltip="Grant lock permissions based on previous lock group share events. The lock selected
            below will be used as a template to copy permissions from.">
        </nb-icon>
        <br />
        <div *ngIf="copyPermissions" class="mt-1">
          <nb-select
            [(ngModel)]="copyPermissionsFrom"
            id="teamMemberSelectDropdown"
            placeholder="Select an existing team member">
            <nb-option *ngFor="let lock of value.Locks" [value]="lock.Id">
              {{ lock.DisplayName }}
            </nb-option>
          </nb-select>
        </div> -->
        <small class="text-muted text-center mt-2">
          New Locks will automatically be granted permission(s) as current locks.
        </small>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton (click)="addSelectedLock()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Done">
      <h6>Success</h6>
      <div class="results">
        <p>Congratulations! You have successfully updated your Lock Group.</p>
        <p>
          You can see the permissions associated with this lock group by clicking on the three dots
          icon and selecting the 'Permissions' menu option.
        </p>
      </div>
    </nb-step>
  </nb-stepper>
</div>
