<nb-card class="container">
  <nb-card-body>
    <div class="col-md-12 form-group">
      <label for="permissionStartDateTime">Description</label>
      <input
        type="text"
        nbInput
        fullWidth
        [(ngModel)]="value.Description"
        placeholder="Unique Code description"
        class="form-control" />
    </div>
    <div class="col-md-12 form-group">
      <label for="permissionStartDateTime">Code Expiry</label>
      <div>
        <a role="button" class="datetime-input">
          <div class="input-group">
            <span class="form-control" id="permissionStartDateTime">
              <span *ngIf="value.ExpiresOnUtc">
                {{ value.ExpiresOnUtc | date: 'dd-MMM-yyyy hh:mm a' }}
              </span>
              <span class="text-muted" *ngIf="!value.ExpiresOnUtc">
                Please select Unique Code expiry
              </span>
            </span>
            <span class="input-group-addon"><i class="fa icon-calendar"></i></span>
          </div>
        </a>
        <small class="text-muted"> The code can only be valid for a maximum of 6 months. </small>
        <ul
          class="dropdown-menu show"
          id="dateTimePickerShareModalStartDate"
          role="menu"
          aria-labelledby="dLabel">
          <dl-date-time-picker
            startView="day"
            maxView="year"
            minView="minute"
            minuteStep="5"
            [(ngModel)]="value.ExpiresOnUtc"
            [selectFilter]="filterDate">
          </dl-date-time-picker>
        </ul>
      </div>
    </div>
    <button nbButton (click)="createUniqueCode()" class="wizard-nav-buttons">Create</button>
  </nb-card-body>
</nb-card>
