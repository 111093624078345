<div class="container">
  <nb-stepper orientation="horizontal" [disableStepNavigation]="true" #stepper>
    <nb-step label="Team">
      <div>
        <lockvue-basic-details [(name)]="value.Name" [(description)]="value.Description">
        </lockvue-basic-details>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton nbStepperNext (click)="editTeam()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Members">
      <h6>Add users to this team</h6>
      <div>
        <nb-tag-list [nbSpinner]="inProgress">
          <input
            placeholder="Enter email and press enter to add member"
            nbInput
            nbTagInput
            fullWidth
            [(ngModel)]="memberToAdd"
            (tagAdd)="addSelectedMember($event)"
            type="email" />
          <nb-tag
            [removable]="false"
            *ngFor="let member of currentTeamMembers"
            [text]="member"></nb-tag>
        </nb-tag-list>
        <div>
          <small class="text-muted text-center mt-2"
            >New Members will automatically be granted permission(s) as current members.</small
          >
        </div>
      </div>
      <div class="wizard-nav-buttons">
        <button nbButton (click)="next()">Next</button>
      </div>
    </nb-step>
    <nb-step label="Done">
      <h6>Success</h6>
      <div class="results">
        <p>Congratulations! You have successfully updated your team.</p>
        <p>
          You can see the permissions associated with this team by clicking on the three dots icon
          and selecting the 'Permissions' menu option.
        </p>
      </div>
    </nb-step>
  </nb-stepper>
</div>
