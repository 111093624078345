import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  TelemetryService,
  SummaryService,
  TelemetrySummary,
  SummaryModel,
} from '../../lockvue-ng-sdk';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'lockvue-system-stats-bar',
  templateUrl: './system-stats-bar.component.html',
  styleUrls: ['./system-stats-bar.component.scss'],
})
export class SystemStatsBarComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  telemetrySummary: TelemetrySummary;
  systemSummary: SummaryModel;

  // TEMPORARY
  // TODO: API TO SEND CORRECT DATA WITHOUT COUNTING LOCK PERMISSIONS
  @Input() lockCount: number;

  constructor(
    private telemetryService: TelemetryService,
    private summaryService: SummaryService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.telemetryService
      .telemetrySummary()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => (this.telemetrySummary = result));

    this.summaryService
      .summaryMy()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => (this.systemSummary = result));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  navigate(dest: any) {
    this.router.navigate([dest]);
  }
}
