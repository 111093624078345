import { Component, OnInit, Input } from '@angular/core';
import { TeamDetailsUIModel } from '../../models/TeamDetailsUIModel';
import { BaseConfirmationWithPermissionsComponent } from '../base-confirmation-with-permissions/base-confirmation-with-permissions.component';
import { PermissionDetailsV2, PermissionsService } from '../../lockvue-ng-sdk';
import { NbDialogRef } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'lockvue-remove-team-confirmation',
  templateUrl: './remove-team-confirmation.component.html',
  styleUrls: ['./remove-team-confirmation.component.scss'],
})
export class RemoveTeamConfirmationComponent extends BaseConfirmationWithPermissionsComponent {
  @Input() team: TeamDetailsUIModel;
  @Input() permissions: PermissionDetailsV2[];
  @Input() revokePermissions: boolean = true;

  constructor(ref: NbDialogRef<ConfirmDialogComponent>, permissionsService: PermissionsService) {
    super(ref, permissionsService);
  }

  submit() {
    super.submit(this.revokePermissions, this.permissions);
  }
}
