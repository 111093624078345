import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbTokenService, NbAuthJWTToken, NbAuthService, NbAuthResult } from '@nebular/auth';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lockvue-auto-login',
  templateUrl: './oauth2-callback.component.html',
  styleUrls: ['./oauth2-callback.component.scss'],
})
export class Oauth2CallbackComponent {
  constructor(private authService: NbAuthService, private router: Router) {
    this.authService
      .authenticate('fusion-auth')
      .pipe(take(1))
      .subscribe((result: NbAuthResult) => {
        if (result.isSuccess()) {
          this.router.navigateByUrl('/dashboard');
        }
      });
  }
}
